import { TYPES } from "./actions";

let initState = {
  registerIsLoading: false,
  registrationError: null,

  link: {},
  getLinkByIdIsLoading: false,
  getLinkByIdError: null,

  attendee: null,

  createLinkIsLoading: false,
  createLinkError: null,

  links: [],
  getLinksIsLoading: false,
  getLinksError: null,

  updateLinkIsLoading: false,
  updateLinkError: null,

  deleteLinksIsLoading: false,
  deleteLinksError: null,

  verifyLinkIsLoading: false,
  verifyLinkError: null
};

export function linksAsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.REGISTER_FROM_LINK:
      return {
        ...state,
        registerIsLoading: false,
        attendee: action.payload
      };
    case TYPES.REGISTER_FROM_LINK_IN_PROGRESS:
      return {
        ...state,
        registerIsLoading: true
      };
    case TYPES.REGISTER_FROM_LINK_ERROR:
      return {
        ...state,
        registrationError: action.payload,
        registerIsLoading: false
      };

    case TYPES.VERIFY_LINK:
      return {
        ...state,
        link: action.payload,
        verifyLinkIsLoading: false
      };
    case TYPES.VERIFY_LINK_IN_PROGRESS:
      return {
        ...state,
        verifyLinkIsLoading: true
      };
    case TYPES.VERIFY_LINK_ERROR:
      return {
        ...state,
        verifyLinkIsLoading: false,
        verifyLinkError: action.payload
      };

    case TYPES.UPDATE_LINK:
      return {
        ...state,
        link: action.payload,
        links: state?.links?.map((link) => {
          if (link?._id === action?.payload?._id) {
            return action?.payload;
          }
          return link;
        }),
        updateLinkIsLoading: false
      };
    case TYPES.UPDATE_LINK_IN_PROGRESS:
      return {
        ...state,
        updateLinkIsLoading: true
      };
    case TYPES.UPDATE_LINK_ERROR:
      return {
        ...state,
        updateLinkIsLoading: false,
        updateLinkError: action.payload
      };

    case TYPES.GET_LINK_BY_ID:
      return {
        ...state,
        link: action.payload,
        getLinkByIdIsLoading: false
      };
    case TYPES.GET_LINK_BY_ID_IN_PROGRESS:
      return {
        ...state,
        getLinkByIdIsLoading: true
      };
    case TYPES.GET_LINK_BY_ID_ERROR:
      return {
        ...state,
        getLinkByIdIsLoading: false,
        getLinkByIdError: action.payload
      };

    case TYPES.GENERATE_NEW_LINK:
      return {
        ...state,
        link: action.payload,
        links: [...state?.links, action.payload],
        createLinkIsLoading: false
      };
    case TYPES.GENERATE_NEW_LINK_IN_PROGRESS:
      return {
        ...state,
        createLinkIsLoading: true
      };
    case TYPES.GENERATE_NEW_LINK_ERROR:
      return {
        ...state,
        createLinkIsLoading: false,
        createLinkError: action.payload
      };

    case TYPES.GET_LINKS:
      return {
        ...state,
        links: action.payload,
        getLinksIsLoading: false
      };
    case TYPES.GET_LINKS_IN_PROGRESS:
      return {
        ...state,
        getLinksIsLoading: true
      };
    case TYPES.GET_LINKS_ERROR:
      return {
        ...state,
        getLinksIsLoading: false,
        getLinksError: action.payload
      };

    case TYPES.GET_LINKS_BY_MANAGER_ID:
      return {
        ...state,
        links: action.payload,
        getLinksIsLoading: false
      };
    case TYPES.GET_LINKS_BY_MANAGER_ID_IN_PROGRESS:
      return {
        ...state,
        getLinksIsLoading: true
      };
    case TYPES.GET_LINKS_BY_MANAGER_ID_ERROR:
      return {
        ...state,
        getLinksIsLoading: false,
        getLinksError: action.payload
      };

    case TYPES.DELETE_LINK:
      const newLinks = state?.links?.filter((link) => {
        return !action?.payload?.includes(link?._id);
      });

      return {
        ...state,
        links: newLinks,
        deleteLinksIsLoading: false
      };
    case TYPES.DELETE_LINK_IN_PROGRESS:
      return {
        ...state,
        deleteLinksIsLoading: true
      };
    case TYPES.DELETE_LINK_ERROR:
      return {
        ...state,
        deleteLinksIsLoading: false,
        deleteLinksError: action.payload
      };
    default:
      return state;
  }
}

import React, { useState, useEffect, useRef } from "react";
import FullBadge from "assets/full.png";
import QRCode from "react-qr-code";
import Invite from "assets/invited.png";
import Visitor from "assets/visitor.png";
import html2canvas from "html2canvas";
import { Button, Card, Col, Row, Space, Spin } from "antd";
import styled from "styled-components";

const HiddenContainer = styled.div`
  position: absolute;
  left: -9999px;
  top: -9999px;
`;

const FullBadgeImage = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.backgroundImage
      ? `url(${props.backgroundImage}) center center / contain no-repeat`
      : "#f0f2f5"};
`;

const InvitationImage = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 66.67%; // This assumes a 3:2 aspect ratio. Adjust if needed.
  position: relative;
  background: ${(props) =>
    props.backgroundImage
      ? `url(${props.backgroundImage}) center center / contain no-repeat`
      : "#f0f2f5"};
  color: ${(props) => (props.backgroundImage ? "white" : "black")};
  font-family: Arial, sans-serif;
`;

const InvitationContent = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const QRCodeContainer = styled.div`
  text-align: ${(props) => (props.center ? "center" : "left")};
  width: 100%;
  position: absolute;
  top: ${(props) => props.topPosition}%;
  left: 50%;
  transform: translateX(-50%);
`;

export default function Badge({ attendee, country }) {
  const containerRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 600, height: 400 });
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const qrCodeSize = 200; // Size of the QR code in pixels
  const qrCodeTopPosition = 82;

  useEffect(() => {
    // Check if the Invitation image can be loaded
    const img = new Image();
    img.onload = () => {
      setBackgroundImage(FullBadge);
      setImageDimensions({ width: img.width, height: img.height });
    };
    img.onerror = () => setBackgroundImage(null);
    img.src = FullBadge;
  }, []);

  const handleDownload = () => {
    if (containerRef.current) {
      html2canvas(containerRef.current, {
        scale: 0.7, // Lower scale for reducing size (1 or 0.5 reduces the size)
        width: imageDimensions.width,
        height: imageDimensions.height
      }).then((canvas) => {
        // Reduce the image quality when exporting
        const image = canvas.toDataURL("image/png", 0.6); // 0.6 is the quality factor (0.0 - 1.0)
        const link = document.createElement("a");
        link.href = image;
        link.download = `${attendee?.firstName || "x"}_${attendee?.lastName || "x"}_invitation.png`;
        link.click();
      });
    }
  };

  // Set a 10s timer for enabling the download button
  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonDisabled(false); // Enable the button after 10s
      setLoading(false); // Stop showing the loading state
    }, 10000);

    return () => clearTimeout(timer); // Clear the timer when the component unmounts
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Card style={{ width: "100%" }}>
        <Row gutter={[8, 16]}>
          <Col
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            span={24}
          >
            <Button size="large" disabled={buttonDisabled} onClick={handleDownload} type="primary">
              {loading ? (
                <Space>
                  <Spin /> <>Télécharger votre badge</>{" "}
                </Space>
              ) : (
                "Télécharger votre badge"
              )}
            </Button>
          </Col>
        </Row>
      </Card>

      <HiddenContainer ref={containerRef}>
        <FullBadgeImage
          style={{
            width: `${imageDimensions.width}px`,
            height: `${imageDimensions.height}px`,
            paddingBottom: 0
          }}
          backgroundImage={FullBadge}
        >
          <InvitationImage
            backgroundImage={attendee?.category === "INVITÉ" ? Invite : Visitor}
            style={{
              width: `${imageDimensions.width / 2}px`,
              height: `${imageDimensions.height / 2}px`,
              paddingBottom: 0
            }}
          >
            <InvitationContent>
              <QRCodeContainer topPosition={qrCodeTopPosition} center>
                <QRCode
                  // style={{ padding: "8px" }}
                  bgColor="#00000000"
                  fgColor="white"
                  value={attendee?._id}
                  size={qrCodeSize}
                />
              </QRCodeContainer>

              {attendee?.category !== "INVITÉ" && (
                <QRCodeContainer topPosition={58.5} center>
                  <h1 style={{ color: "white", fontSize: "70px" }}>{attendee?.category}</h1>
                </QRCodeContainer>
              )}

              <QRCodeContainer topPosition={66.5} center>
                <h1 style={{ color: "black", fontSize: "50px" }}>
                  {attendee?.firstName?.toUpperCase()} {attendee?.lastName?.toUpperCase()}
                </h1>
              </QRCodeContainer>

              <QRCodeContainer topPosition={72} center>
                <h1 style={{ color: "black", fontSize: "60px", fontWeight: "600" }}>
                  {country?.name}
                </h1>
              </QRCodeContainer>

              <QRCodeContainer topPosition={94}>
                <h1 style={{ color: "white", fontSize: "30px", margin: "5px" }}>
                  {attendee?.typeDeRestauration === "in-house"
                    ? "IH "
                    : attendee?.typeDeRestauration === "out-house"
                    ? "OH "
                    : ""}
                  - {attendee?.restaurationNumber || ""}
                </h1>{" "}
              </QRCodeContainer>
            </InvitationContent>
          </InvitationImage>
        </FullBadgeImage>
      </HiddenContainer>
    </div>
  );
}

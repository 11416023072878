import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";

export const TYPES = {
  GET_BADGES: "GET_BADGES",
  GET_BADGES_IN_PROGRESS: "GET_BADGES_IN_PROGRESS",
  GET_BADGES_ERROR: "GET_BADGES_ERROR",

  GET_BADGE_BY_ID: "GET_BADGE_BY_ID",
  GET_BADGE_BY_ID_IN_PROGRESS: "GET_BADGE_BY_ID_IN_PROGRESS",
  GET_BADGE_BY_ID_ERROR: "GET_BADGE_BY_ID_ERROR",

  CREATE_NEW_BADGE: "CREATE_NEW_BADGE",
  CREATE_NEW_BADGE_IN_PROGRESS: "CREATE_NEW_BADGE_IN_PROGRESS",
  CREATE_NEW_BADGE_ERROR: "CREATE_NEW_BADGE_ERROR",

  UPDATE_BADGE: "UPDATE_BADGE",
  UPDATE_BADGE_IN_PROGRESS: "UPDATE_BADGE_IN_PROGRESS",
  UPDATE_BADGE_ERROR: "UPDATE_BADGE_ERROR",

  DELETE_BADGE: "DELETE_BADGE",
  DELETE_BADGE_IN_PROGRESS: "DELETE_BADGE_IN_PROGRESS",
  DELETE_BADGE_ERROR: "DELETE_BADGE_ERROR"
};

export function getBadges(type = "") {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_BADGES_IN_PROGRESS });
    axiosInstance
      .get(`/api/badges/${type}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_BADGES,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_BADGES_ERROR,
          payload: err?.response?.data || { message: "Failed to get Orders" }
        });
      });
  };
}

export function getBadgeById(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_BADGE_BY_ID_IN_PROGRESS });
    axiosInstance
      .get(`/api/badge/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_BADGE_BY_ID,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_BADGE_BY_ID_ERROR,
          payload: err?.response?.data || { message: "Failed to get Order" }
        });
      });
  };
}

export function createBadge(order) {
  return function (dispatch) {
    dispatch({ type: TYPES.CREATE_NEW_BADGE_IN_PROGRESS });
    axiosInstance
      .post("/api/badges/", order)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Badge Added Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });

        dispatch({
          type: TYPES.CREATE_NEW_BADGE,
          payload: response
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CREATE_NEW_BADGE_ERROR,
          payload: err?.response?.data || {
            message: "Failed to create new order"
          }
        });
      });
  };
}

export function editBadge(id, badge) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_BADGE_IN_PROGRESS });
    axiosInstance
      .put(`/api/badges/update/${id}`, badge)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Badge Updated Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });

        dispatch({
          type: TYPES.UPDATE_BADGE,
          payload: response
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_BADGE_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Edit Badge"
          }
        });
        Swal.fire({
          icon: "error",
          title: "Failed To Edit Badge",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
      });
  };
}

export function deleteBadge(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.DELETE_BADGE_IN_PROGRESS });
    axiosInstance
      .delete(`/api/badges/delete/${id}`)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Badge Deleted Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
        dispatch({
          type: TYPES.DELETE_BADGE,
          payload: [id]
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.DELETE_BADGE_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Delete Badge"
          }
        });
      });
  };
}

import { TYPES } from "./actions";

let initState = {
  account: {},
  getAccountByIdIsLoading: false,
  getAccountByIdError: null,

  createAccountIsLoading: false,
  createAccountError: null,

  accounts: [],
  getAccountsIsLoading: false,
  getAccountsError: null,

  updateAccountIsLoading: false,
  updateAccountError: null,

  deleteAccountsIsLoading: false,
  deleteAccountsError: null
};

export function accountsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.UPDATE_ACCOUNT:
      return {
        ...state,
        account: action.payload,
        accounts: state?.accounts?.map((account) => {
          if (account?._id === action?.payload?.data?._id) {
            return action?.payload?.data;
          }
          return account;
        }),
        updateAccountIsLoading: false
      };
    case TYPES.UPDATE_ACCOUNT_IN_PROGRESS:
      return {
        ...state,
        updateAccountIsLoading: true
      };
    case TYPES.UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        updateAccountIsLoading: false,
        updateAccountError: action.payload
      };

    case TYPES.GET_ACCOUNT_BY_ID:
      return {
        ...state,
        account: action.payload,
        getAccountByIdIsLoading: false
      };
    case TYPES.GET_ACCOUNT_BY_ID_IN_PROGRESS:
      return {
        ...state,
        getAccountByIdIsLoading: true
      };
    case TYPES.GET_ACCOUNT_BY_ID_ERROR:
      return {
        ...state,
        getAccountByIdIsLoading: false,
        getAccountByIdError: action.payload
      };

    case TYPES.CREATE_NEW_ACCOUNT:
      return {
        ...state,
        account: action.payload,
        accounts: [...state?.accounts, action.payload?.data],
        createAccountIsLoading: false
      };
    case TYPES.CREATE_NEW_ACCOUNT_IN_PROGRESS:
      return {
        ...state,
        createAccountIsLoading: true
      };
    case TYPES.CREATE_NEW_ACCOUNT_ERROR:
      return {
        ...state,
        createAccountIsLoading: false,
        createAccountError: action.payload
      };

    case TYPES.GET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        getAccountsIsLoading: false
      };
    case TYPES.GET_ACCOUNTS_IN_PROGRESS:
      return {
        ...state,
        getAccountsIsLoading: true
      };
    case TYPES.GET_ACCOUNTS_ERROR:
      return {
        ...state,
        getAccountsIsLoading: false,
        getAccountsError: action.payload
      };

    case TYPES.DELETE_ACCOUNT:
      const newAccounts = state?.accounts?.filter((account) => {
        return !action?.payload?.includes(account?._id);
      });

      return {
        ...state,
        accounts: newAccounts,
        deleteAccountsIsLoading: false
      };
    case TYPES.DELETE_ACCOUNT_IN_PROGRESS:
      return {
        ...state,
        deleteAccountsIsLoading: true
      };
    case TYPES.DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        deleteAccountsIsLoading: false,
        deleteAccountsError: action.payload
      };
    default:
      return state;
  }
}

import { TYPES } from "./actions";

let initState = {
  badge: {},
  getBadgeByIdIsLoading: false,
  getBadgeByIdError: null,

  createBadgeIsLoading: false,
  createBadgeError: null,

  badges: [],
  getBadgesIsLoading: false,
  getBadgesError: null,

  updateBadgeIsLoading: false,
  updateBadgeError: null,

  deleteBadgeIsLoading: false,
  deleteBadgeError: null
};

export function badgesAsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.UPDATE_BADGE:
      return {
        ...state,
        badge: action.payload,
        updateBadgeIsLoading: false
      };
    case TYPES.UPDATE_BADGE_IN_PROGRESS:
      return {
        ...state,
        updateBadgeIsLoading: true
      };
    case TYPES.UPDATE_BADGE_ERROR:
      return {
        ...state,
        updateBadgeIsLoading: false,
        updateBadgeError: action.payload
      };

    case TYPES.GET_BADGE_BY_ID:
      return {
        ...state,
        badge: action.payload,
        getBadgeByIdIsLoading: false
      };
    case TYPES.GET_BADGE_BY_ID_IN_PROGRESS:
      return {
        ...state,
        getBadgeByIdIsLoading: true
      };
    case TYPES.GET_BADGE_BY_ID_ERROR:
      return {
        ...state,
        getBadgeByIdIsLoading: false,
        getBadgeByIdError: action.payload
      };

    case TYPES.CREATE_NEW_BADGE:
      return {
        ...state,
        badge: action.payload,
        createBadgeIsLoading: false
      };
    case TYPES.CREATE_NEW_BADGE_IN_PROGRESS:
      return {
        ...state,
        createBadgeIsLoading: true
      };
    case TYPES.CREATE_NEW_BADGE_ERROR:
      return {
        ...state,
        createBadgeIsLoading: false,
        createBadgeError: action.payload
      };

    case TYPES.GET_BADGES:
      return {
        ...state,
        badges: action.payload,
        getBadgesIsLoading: false
      };
    case TYPES.GET_BADGES_IN_PROGRESS:
      return {
        ...state,
        getBadgesIsLoading: true
      };
    case TYPES.GET_BADGES_ERROR:
      return {
        ...state,
        getBadgesIsLoading: false,
        getBadgesError: action.payload
      };

    case TYPES.DELETE_BADGE:
      const newPlats = state?.badges?.filter((badge) => {
        return !action?.payload?.includes(badge?._id);
      });

      return {
        ...state,
        badges: newPlats,
        deleteBadgeIsLoading: false
      };
    case TYPES.DELETE_BADGE_IN_PROGRESS:
      return {
        ...state,
        deleteBadgeIsLoading: true
      };
    case TYPES.DELETE_BADGE_ERROR:
      return {
        ...state,
        deleteBadgeIsLoading: false,
        deleteBadgeError: action.payload
      };
    default:
      return state;
  }
}

import { TYPES } from "./actions";

let initState = {
  ticket: {},
  getTicketByIdIsLoading: false,
  getTicketByIdError: null,

  getTicketsByUserIdIsLoading: false,
  getTicketsByUserIdError: null,

  createTicketIsLoading: false,
  createTicketError: null,

  tickets: [],
  getTicketsIsLoading: false,
  getTicketsError: null,

  updateTicketIsLoading: false,
  updateTicketError: null,

  deleteTicketsIsLoading: false,
  deleteTicketsError: null,

  addCommentToTicketIsLoading: false,
  addCommentToTicketError: null,

  deleteCommentFromTicketIsLoading: false,
  deleteCommentFromTicketError: null
};

export function rahalTicketsAsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.ADD_COMMENT_TO_TICKET:
      return {
        ...state,
        ticket: action.payload,
        addCommentToTicketIsLoading: false
      };
    case TYPES.ADD_COMMENT_TO_TICKET_IN_PROGRESS:
      return {
        ...state,
        addCommentToTicketIsLoading: true
      };
    case TYPES.ADD_COMMENT_TO_TICKET_ERROR:
      return {
        ...state,
        addCommentToTicketIsLoading: false,
        addCommentToTicketError: action.payload
      };

    case TYPES.DELETE_COMMENT_FROM_TICKET:
      return {
        ...state,
        ticket: action.payload,
        deleteCommentFromTicketIsLoading: false
      };
    case TYPES.DELETE_COMMENT_FROM_TICKET_IN_PROGRESS:
      return {
        ...state,
        deleteCommentFromTicketIsLoading: true
      };
    case TYPES.DELETE_COMMENT_FROM_TICKET_ERROR:
      return {
        ...state,
        deleteCommentFromTicketIsLoading: false,
        deleteCommentFromTicketError: action.payload
      };

    case TYPES.GET_TICKETS:
      return {
        ...state,
        tickets: action.payload,
        getTicketsIsLoading: false
      };
    case TYPES.GET_TICKETS_IN_PROGRESS:
      return {
        ...state,
        getTicketsIsLoading: true
      };
    case TYPES.GET_TICKETS_ERROR:
      return {
        ...state,
        getTicketsIsLoading: false,
        getTicketsError: action.payload
      };

    case TYPES.GET_TICKETS_BY_USER_ID:
      return {
        ...state,
        tickets: action.payload,
        getTicketsByUserIdIsLoading: false
      };
    case TYPES.GET_TICKETS_BY_USER_ID_IN_PROGRESS:
      return {
        ...state,
        getTicketsByUserIdIsLoading: true
      };
    case TYPES.GET_TICKETS_BY_USER_ID_ERROR:
      return {
        ...state,
        getTicketsByUserIdIsLoading: false,
        getTicketsByUserIdError: action.payload
      };

    case TYPES.GET_TICKET_BY_ID:
      return {
        ...state,
        ticket: action.payload,
        getTicketByIdIsLoading: false
      };
    case TYPES.GET_TICKET_BY_ID_IN_PROGRESS:
      return {
        ...state,
        getTicketByIdIsLoading: true
      };
    case TYPES.GET_TICKET_BY_ID_ERROR:
      return {
        ...state,
        getTicketByIdIsLoading: false,
        getTicketByIdError: action.payload
      };

    case TYPES.CREATE_NEW_TICKET:
      return {
        ...state,
        ticket: action.payload,
        createTicketIsLoading: false
      };
    case TYPES.CREATE_NEW_TICKET_IN_PROGRESS:
      return {
        ...state,
        createTicketIsLoading: true
      };
    case TYPES.CREATE_NEW_TICKET_ERROR:
      return {
        ...state,
        createTicketIsLoading: false,
        createTicketError: action.payload
      };

    case TYPES.UPDATE_TICKET:
      return {
        ...state,
        ticket: action.payload,
        updateTicketIsLoading: false
      };
    case TYPES.UPDATE_TICKET_IN_PROGRESS:
      return {
        ...state,
        updateTicketIsLoading: true
      };
    case TYPES.UPDATE_TICKET_ERROR:
      return {
        ...state,
        updateTicketIsLoading: false,
        updateTicketError: action.payload
      };

    case TYPES.DELETE_TICKET:
      return {
        ...state,
        tickets: action.payload,
        deleteTicketsIsLoading: false
      };
    case TYPES.DELETE_TICKET_IN_PROGRESS:
      return {
        ...state,
        deleteTicketsIsLoading: true
      };
    case TYPES.DELETE_TICKET_ERROR:
      return {
        ...state,
        deleteTicketsIsLoading: false,
        deleteTicketsError: action.payload
      };

    default:
      return state;
  }
}

import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";

export const TYPES = {
  GET_ACCOUNTS: "GET_ACCOUNTS",
  GET_ACCOUNTS_IN_PROGRESS: "GET_ACCOUNTS_IN_PROGRESS",
  GET_ACCOUNTS_ERROR: "GET_ACCOUNTS_ERROR",

  GET_ACCOUNT_BY_ID: "GET_ACCOUNT_BY_ID",
  GET_ACCOUNT_BY_ID_IN_PROGRESS: "GET_ACCOUNT_BY_ID_IN_PROGRESS",
  GET_ACCOUNT_BY_ID_ERROR: "GET_ACCOUNT_BY_ID_ERROR",

  CREATE_NEW_ACCOUNT: "CREATE_NEW_ACCOUNT",
  CREATE_NEW_ACCOUNT_IN_PROGRESS: "CREATE_NEW_ACCOUNT_IN_PROGRESS",
  CREATE_NEW_ACCOUNT_ERROR: "CREATE_NEW_ACCOUNT_ERROR",

  UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
  UPDATE_ACCOUNT_IN_PROGRESS: "UPDATE_ACCOUNT_IN_PROGRESS",
  UPDATE_ACCOUNT_ERROR: "UPDATE_ACCOUNT_ERROR",

  DELETE_ACCOUNT: "DELETE_ACCOUNT",
  DELETE_ACCOUNT_IN_PROGRESS: "DELETE_ACCOUNT_IN_PROGRESS",
  DELETE_ACCOUNT_ERROR: "DELETE_ACCOUNT_ERROR"
};

export function getAccounts() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ACCOUNTS_IN_PROGRESS });
    axiosInstance
      .get("/api/accounts")
      .then((res) => {
        dispatch({
          type: TYPES.GET_ACCOUNTS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_ACCOUNTS_ERROR,
          payload: err?.response?.data || { message: "Failed to get accounts" }
        });
      });
  };
}

export function getAccountsByUserId(userId) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ACCOUNTS_IN_PROGRESS });
    axiosInstance
      .get(`/api/accounts/user/${userId}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ACCOUNTS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_ACCOUNTS_ERROR,
          payload: err?.response?.data || { message: "Failed to get accounts" }
        });
      });
  };
}

export function getAccountById(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ACCOUNT_BY_ID_IN_PROGRESS });
    axiosInstance
      .get(`/api/account/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ACCOUNT_BY_ID,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_ACCOUNT_BY_ID_ERROR,
          payload: err?.response?.data || { message: "Failed to get account" }
        });
      });
  };
}

export function createAccount(account) {
  return function (dispatch) {
    dispatch({ type: TYPES.CREATE_NEW_ACCOUNT_IN_PROGRESS });
    axiosInstance
      .post("/api/account", account)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Account Created Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        }).then((result) => {
          console.log({ result });
        });

        dispatch({
          type: TYPES.CREATE_NEW_ACCOUNT,
          payload: response
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CREATE_NEW_ACCOUNT_ERROR,
          payload: err?.response?.data || {
            message: "Failed to create new account"
          }
        });
      });
  };
}

export function updateAccount(account, id) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_ACCOUNT_IN_PROGRESS });
    axiosInstance
      .put(`/api/account/${id}`, account)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Account Updated Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        }).then((result) => {
          console.log({ result });
        });

        dispatch({
          type: TYPES.UPDATE_ACCOUNT,
          payload: response
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_ACCOUNT_ERROR,
          payload: err?.response?.data || {
            message: "Failed to update account"
          }
        });
      });
  };
}

export function deleteAccount(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.DELETE_ACCOUNT_IN_PROGRESS });
    axiosInstance
      .delete(`/api/account/${id}`)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Account Deleted Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
        dispatch({
          type: TYPES.DELETE_ACCOUNT,
          payload: [id]
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.DELETE_ACCOUNT_ERROR,
          payload: err?.response?.data || {
            message: "Failed to delete account"
          }
        });
      });
  };
}

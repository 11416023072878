import { TYPES } from "./actions";

let initState = {
  order: {},
  getOrderByIdIsLoading: false,
  getOrderByIdError: null,

  deliveryOrders: [],
  getDeliveryOrdersIsLoading: false,

  productionOrders: [],
  getOrdersProductionIsLoading: false,

  createOrderIsLoading: false,
  createOrderError: null,

  orders: [],
  getOrdersIsLoading: false,
  getOrdersError: null,

  updateOrderIsLoading: false,
  updateOrderError: null,

  analytics: null,
  isLoading: false,

  confirmDeliveryIsLoading: false,
  confirmDeliveryError: null
};

export function rahalOrdersAsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.CONFIRM_DELIVERY:
      return {
        ...state,
        order: action.payload,
        confirmDeliveryIsLoading: false
      };
    case TYPES.CONFIRM_DELIVERY_IN_PROGRESS:
      return {
        ...state,
        confirmDeliveryIsLoading: true
      };
    case TYPES.CONFIRM_DELIVERY_ERROR:
      return {
        ...state,
        confirmDeliveryIsLoading: false,
        confirmDeliveryError: action.payload
      };

    case TYPES.GET_DELIVERY_ORDERS:
      return {
        ...state,
        deliveryOrders: action.payload,
        getDeliveryOrdersIsLoading: false
      };
    case TYPES.GET_DELIVERY_ORDERS_IN_PROGRESS:
      return {
        ...state,
        getDeliveryOrdersIsLoading: true
      };
    case TYPES.GET_DELIVERY_ORDERS_ERROR:
      return {
        ...state,
        getDeliveryOrdersIsLoading: false
      };

    case TYPES.GET_ORDERS_PRODUCTION:
      return {
        ...state,
        productionOrders: action.payload,
        getOrdersProductionIsLoading: false
      };
    case TYPES.GET_ORDERS_PRODUCTION_IN_PROGRESS:
      return {
        ...state,
        getOrdersProductionIsLoading: true
      };
    case TYPES.GET_ORDERS_PRODUCTION_ERROR:
      return {
        ...state,
        getOrdersProductionIsLoading: false
      };
    case TYPES.GET_ANALYTICS:
      return {
        ...state,
        analytics: action.payload,
        isLoading: false
      };
    case TYPES.GET_ANALYTICS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case TYPES.GET_ANALYTICS_ERROR:
      return {
        ...state,
        isLoading: false
      };

    case TYPES.UPDATE_ORDER:
      const updatedOrder = action.payload; // Assuming action.payload is the updated order object

      // Find the index of the order you want to update in the "orders" array
      const orderIndex = state.orders.findIndex((order) => order._id === updatedOrder._id);

      // Create a new copy of the "orders" array with the updated order
      const updatedOrders = [...state.orders];
      updatedOrders[orderIndex] = updatedOrder;

      return {
        ...state,
        orders: updatedOrders,
        order: action.payload,
        updateOrderIsLoading: false
      };
    case TYPES.UPDATE_ORDER_IN_PROGRESS:
      return {
        ...state,
        updateOrderIsLoading: true
      };
    case TYPES.UPDATE_ORDER_ERROR:
      return {
        ...state,
        updateOrderIsLoading: false,
        updateOrderError: action.payload
      };

    case TYPES.GET_ORDER_BY_ID:
      return {
        ...state,
        order: action.payload,
        getOrderByIdIsLoading: false
      };
    case TYPES.GET_ORDER_BY_ID_IN_PROGRESS:
      return {
        ...state,
        getOrderByIdIsLoading: true
      };
    case TYPES.GET_ORDER_BY_ID_ERROR:
      return {
        ...state,
        getOrderByIdIsLoading: false,
        getOrderByIdError: action.payload
      };

    case TYPES.CREATE_NEW_ORDER:
      return {
        ...state,
        order: action.payload,
        createOrderIsLoading: false
      };
    case TYPES.CREATE_NEW_ORDER_IN_PROGRESS:
      return {
        ...state,
        createOrderIsLoading: true
      };
    case TYPES.CREATE_NEW_ORDER_ERROR:
      return {
        ...state,
        createOrderIsLoading: false,
        createOrderError: action.payload
      };

    case TYPES.GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
        getOrdersIsLoading: false
      };
    case TYPES.GET_ORDERS_IN_PROGRESS:
      return {
        ...state,
        getOrdersIsLoading: true
      };
    case TYPES.GET_ORDERS_ERROR:
      return {
        ...state,
        getOrdersIsLoading: false,
        getOrdersError: action.payload
      };
    default:
      return state;
  }
}

import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";

export const TYPES = {
  GET_EVENT: "GET_EVENT",
  GET_EVENT_IN_PROGRESS: "GET_EVENT_IN_PROGRESS",
  GET_EVENT_ERROR: "GET_EVENT_ERROR",

  UPDATE_EVENT: "UPDATE_EVENT",
  UPDATE_EVENT_IN_PROGRESS: "UPDATE_EVENT_IN_PROGRESS",
  UPDATE_EVENT_ERROR: "UPDATE_EVENT_ERROR",

  GET_SIDE_EVENTS: "GET_SIDE_EVENTS",
  GET_SIDE_EVENTS_IN_PROGRESS: "GET_SIDE_EVENTS_IN_PROGRESS",
  GET_SIDE_EVENTS_ERROR: "GET_SIDE_EVENTS_ERROR",

  GET_SIDE_EVENT_BY_ID: "GET_SIDE_EVENT_BY_ID",
  GET_SIDE_EVENT_BY_ID_IN_PROGRESS: "GET_SIDE_EVENT_BY_ID_IN_PROGRESS",
  GET_SIDE_EVENT_BY_ID_ERROR: "GET_SIDE_EVENT_BY_ID_ERROR",

  CREATE_SIDE_EVENT: "CREATE_SIDE_EVENT",
  CREATE_SIDE_EVENT_IN_PROGRESS: "CREATE_SIDE_EVENT_IN_PROGRESS",
  CREATE_SIDE_EVENT_ERROR: "CREATE_SIDE_EVENT_ERROR",

  UPDATE_SIDE_EVENT: "UPDATE_SIDE_EVENT",
  UPDATE_SIDE_EVENT_IN_PROGRESS: "UPDATE_SIDE_EVENT_IN_PROGRESS",
  UPDATE_SIDE_EVENT_ERROR: "UPDATE_SIDE_EVENT_ERROR",

  CONFIRM_SIDE_EVENT: "CONFIRM_SIDE_EVENT",
  CONFIRM_SIDE_EVENT_IN_PROGRESS: "CONFIRM_SIDE_EVENT_IN_PROGRESS",
  CONFIRM_SIDE_EVENT_ERROR: "CONFIRM_SIDE_EVENT_ERROR",

  REJECT_SIDE_EVENT: "REJECT_SIDE_EVENT",
  REJECT_SIDE_EVENT_IN_PROGRESS: "REJECT_SIDE_EVENT_IN_PROGRESS",
  REJECT_SIDE_EVENT_ERROR: "REJECT_SIDE_EVENT_ERROR",

  DELETE_SIDE_EVENT: "DELETE_SIDE_EVENT",
  DELETE_SIDE_EVENT_IN_PROGRESS: "DELETE_SIDE_EVENT_IN_PROGRESS",
  DELETE_SIDE_EVENT_ERROR: "DELETE_SIDE_EVENT_ERROR"
};

export function deleteSideEvent(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.DELETE_SIDE_EVENT_IN_PROGRESS });
    axiosInstance
      .put(`/api/side-event/delete/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.DELETE_SIDE_EVENT,
          payload: [id]
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.DELETE_SIDE_EVENT_ERROR,
          payload: err?.response?.data || { message: "Failed to delete Event" }
        });
      });
  };
}

export function rejectSideEvent(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.REJECT_SIDE_EVENT_IN_PROGRESS });
    axiosInstance
      .put(`/api/side-event/reject/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.REJECT_SIDE_EVENT,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.REJECT_SIDE_EVENT_ERROR,
          payload: err?.response?.data || { message: "Failed to Edit Event" }
        });
      });
  };
}

export function confirmSideEvent(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.CONFIRM_SIDE_EVENT_IN_PROGRESS });
    axiosInstance
      .put(`/api/side-event/confirm/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.CONFIRM_SIDE_EVENT,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.CONFIRM_SIDE_EVENT_ERROR,
          payload: err?.response?.data || { message: "Failed to Edit Event" }
        });
      });
  };
}

export function editSideEvent(sideEvent, id) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_SIDE_EVENT_IN_PROGRESS });
    axiosInstance
      .put(`/api/side-event/update/${id}`, sideEvent)
      .then((res) => {
        dispatch({
          type: TYPES.UPDATE_SIDE_EVENT,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.UPDATE_SIDE_EVENT_ERROR,
          payload: err?.response?.data || { message: "Failed to Edit Event" }
        });
      });
  };
}

export function createSideEvent(sideEvent) {
  return function (dispatch) {
    dispatch({ type: TYPES.CREATE_SIDE_EVENT_IN_PROGRESS });
    axiosInstance
      .post(`/api/side-event/create`, sideEvent)
      .then((res) => {
        dispatch({
          type: TYPES.CREATE_SIDE_EVENT,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.CREATE_SIDE_EVENT_ERROR,
          payload: err?.response?.data || { message: "Failed to get Event" }
        });
      });
  };
}

export function getSideEventById(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_SIDE_EVENTS_IN_PROGRESS });
    axiosInstance
      .get(`/api/side-event/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_SIDE_EVENT_BY_ID,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_SIDE_EVENT_BY_ID_ERROR,
          payload: err?.response?.data || { message: "Failed to get Event" }
        });
      });
  };
}

export function getSideEvents() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_SIDE_EVENTS_IN_PROGRESS });
    axiosInstance
      .get(`/api/side-events`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_SIDE_EVENTS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_SIDE_EVENTS_ERROR,
          payload: err?.response?.data || { message: "Failed to get Event" }
        });
      });
  };
}

export function getEvent() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_EVENT_IN_PROGRESS });
    axiosInstance
      .get(`/api/event`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_EVENT,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_EVENT_ERROR,
          payload: err?.response?.data || { message: "Failed to get Event" }
        });
      });
  };
}

export function updateEvent(event) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_EVENT_IN_PROGRESS });
    axiosInstance
      .put("/api/event/update", event)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Event Updated Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        }).then((result) => {
          console.log({ result });
        });

        dispatch({
          type: TYPES.CREATE_BACKTEST,
          payload: response
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_EVENT_ERROR,
          payload: err?.response?.data || {
            message: "Failed to update event"
          }
        });
      });
  };
}

import React, { Component } from "react";
import { connect } from "react-redux";
import Toast from '../components/Toast'
import * as actions from '../redux/toaster/actions'


class Toasts extends Component {
  render() {
    const { toasts, removeToast } = this.props
    return(
      <div id="toast-container" className="toast-top-right">
        {
          toasts.map(toast => <Toast
            key={toast.id}
            message={toast.message}
            type={toast.type}
            onClose={() => removeToast(toast.id)}/>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = ({ toaster }) => ({ toasts: toaster.toasts })

export default connect(
  mapStateToProps,
  {
    removeToast: actions.removeToast
  }
)(Toasts);
import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";

export const TYPES = {
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_CATEGORIES_IN_PROGRESS: "GET_CATEGORIES_IN_PROGRESS",
  GET_CATEGORIES_ERROR: "GET_CATEGORIES_ERROR",

  GET_CATEGORY_BY_ID: "GET_CATEGORY_BY_ID",
  GET_CATEGORY_BY_ID_IN_PROGRESS: "GET_CATEGORY_BY_ID_IN_PROGRESS",
  GET_CATEGORY_BY_ID_ERROR: "GET_CATEGORY_BY_ID_ERROR",

  CREATE_NEW_CATEGORY: "CREATE_NEW_CATEGORY",
  CREATE_NEW_CATEGORY_IN_PROGRESS: "CREATE_NEW_CATEGORY_IN_PROGRESS",
  CREATE_NEW_CATEGORY_ERROR: "CREATE_NEW_CATEGORY_ERROR",

  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  UPDATE_CATEGORY_IN_PROGRESS: "UPDATE_CATEGORY_IN_PROGRESS",
  UPDATE_CATEGORY_ERROR: "UPDATE_CATEGORY_ERROR",

  DELETE_CATEGORY: "DELETE_CATEGORY",
  DELETE_CATEGORY_IN_PROGRESS: "DELETE_CATEGORY_IN_PROGRESS",
  DELETE_CATEGORY_ERROR: "DELETE_CATEGORY_ERROR"
};

export function getCategories(type = "") {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_CATEGORIES_IN_PROGRESS });
    axiosInstance
      .get(`/api/categories/${type}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_CATEGORIES,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_CATEGORIES_ERROR,
          payload: err?.response?.data || { message: "Failed to get Orders" }
        });
      });
  };
}

export function getCategoryById(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_CATEGORY_BY_ID_IN_PROGRESS });
    axiosInstance
      .get(`/api/category/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_CATEGORY_BY_ID,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_CATEGORY_BY_ID_ERROR,
          payload: err?.response?.data || { message: "Failed to get Order" }
        });
      });
  };
}

export function createCategory(order) {
  return function (dispatch) {
    dispatch({ type: TYPES.CREATE_NEW_CATEGORY_IN_PROGRESS });
    axiosInstance
      .post("/api/categories/", order)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Category Added Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });

        dispatch({
          type: TYPES.CREATE_NEW_CATEGORY,
          payload: response
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CREATE_NEW_CATEGORY_ERROR,
          payload: err?.response?.data || {
            message: "Failed to create new order"
          }
        });
        console.log({ err });
        Swal.fire({
          icon: "error",
          title: "There's a category with the same name in your list !",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
      });
  };
}

export function editCategory(id, category) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_CATEGORY_IN_PROGRESS });
    axiosInstance
      .put(`/api/categories/${id}`, category)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Category Updated Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });

        dispatch({
          type: TYPES.UPDATE_CATEGORY,
          payload: response
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_CATEGORY_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Edit Category"
          }
        });
        Swal.fire({
          icon: "error",
          title: "Failed To Edit Category",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
      });
  };
}

export function deleteCategory(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.DELETE_CATEGORY_IN_PROGRESS });
    axiosInstance
      .delete(`/api/categories/${id}`)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Category Deleted Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
        dispatch({
          type: TYPES.DELETE_CATEGORY,
          payload: [id]
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.DELETE_CATEGORY_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Delete Category"
          }
        });
      });
  };
}

import { TYPES } from "./actions";

let initState = {
  plat: {},
  getPlatByIdIsLoading: false,
  getPlatByIdError: null,

  createPlatIsLoading: false,
  createPlatError: null,

  plats: [],
  getPlatsIsLoading: false,
  getPlatsError: null,

  updatePlatIsLoading: false,
  updatePlatError: null,

  deletePlatsIsLoading: false,
  deletePlatsError: null
};

export function rahalPlatsAsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.UPDATE_PLAT:
      return {
        ...state,
        plat: action.payload,
        updatePlatIsLoading: false
      };
    case TYPES.UPDATE_PLAT_IN_PROGRESS:
      return {
        ...state,
        updatePlatIsLoading: true
      };
    case TYPES.UPDATE_PLAT_ERROR:
      return {
        ...state,
        updatePlatIsLoading: false,
        updatePlatError: action.payload
      };

    case TYPES.GET_PLAT_BY_ID:
      return {
        ...state,
        plat: action.payload,
        getPlatByIdIsLoading: false
      };
    case TYPES.GET_PLAT_BY_ID_IN_PROGRESS:
      return {
        ...state,
        getPlatByIdIsLoading: true
      };
    case TYPES.GET_PLAT_BY_ID_ERROR:
      return {
        ...state,
        getPlatByIdIsLoading: false,
        getPlatByIdError: action.payload
      };

    case TYPES.CREATE_NEW_PLAT:
      return {
        ...state,
        plat: action.payload,
        createPlatIsLoading: false
      };
    case TYPES.CREATE_NEW_PLAT_IN_PROGRESS:
      return {
        ...state,
        createPlatIsLoading: true
      };
    case TYPES.CREATE_NEW_PLAT_ERROR:
      return {
        ...state,
        createPlatIsLoading: false,
        createPlatError: action.payload
      };

    case TYPES.GET_PLATS:
      return {
        ...state,
        plats: action.payload,
        getPlatsIsLoading: false
      };
    case TYPES.GET_PLATS_IN_PROGRESS:
      return {
        ...state,
        getPlatsIsLoading: true
      };
    case TYPES.GET_PLATS_ERROR:
      return {
        ...state,
        getPlatsIsLoading: false,
        getPlatsError: action.payload
      };

    case TYPES.DELETE_PLAT:
      const newPlats = state?.plats?.filter((plat) => {
        return !action?.payload?.includes(plat?._id);
      });

      return {
        ...state,
        plats: newPlats,
        deletePlatsIsLoading: false
      };
    case TYPES.DELETE_PLAT_IN_PROGRESS:
      return {
        ...state,
        deletePlatsIsLoading: true
      };
    case TYPES.DELETE_PLAT_ERROR:
      return {
        ...state,
        deletePlatsIsLoading: false,
        deletePlatsError: action.payload
      };
    default:
      return state;
  }
}

import React from "react";
import { Input } from "antd";
import { InputLabel, InputLabelError, ErrorMessage } from "./styles";

const { Password } = Input;

export const AntInput = ({ ...props }) => {
  return (
    <>
      {props.error ? (
        <>
          <InputLabelError>{props.label}</InputLabelError>
          <ErrorMessage>{props.error}</ErrorMessage>
        </>
      ) : (
        <InputLabel>{props.label}</InputLabel>
      )}
      {props.required ? (
        <InputLabel style={{ color: "red", marginLeft: "5px" }}>*</InputLabel>
      ) : (
        <></>
      )}
      {props.isPassword ? <Password {...props} /> : <Input {...props} />}
    </>
  );
};

import React, { useState } from "react";
import { Upload, Button, Progress, message, Card } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { InputLabel, InputLabelError, ErrorMessage } from "../../styles";
import { v4 as uuidv4 } from "uuid";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const { Dragger } = Upload;

// Your Firebase configuration

const firebaseConfig = {
  // Add your Firebase configuration here
  apiKey: "AIzaSyAdr_3Tv8DaPjo4sWLlGJqFzixupNiGVxY",
  authDomain: "third-circle-388008.firebaseapp.com",
  projectId: "third-circle-388008",
  storageBucket: "third-circle-388008.appspot.com",
  messagingSenderId: "813138552404",
  appId: "1:813138552404:web:2028fb124a3067625cafc1",
  measurementId: "G-Z6JDKQ7YC0"
};

const firebaseApp = initializeApp(firebaseConfig);
const storage = getStorage(firebaseApp);

const ImageUploader = ({ label, required, error, setFieldValue, fieldName, resourceType }) => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleUpload = async () => {
    if (file) {
      const storageRef = ref(storage, `${resourceType}/${uuidv4()}_${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading image:", error);
        },
        async () => {
          message.success(`${file?.name} uploaded successfully!`);
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          setFieldValue(fieldName, url);
        }
      );

      try {
        await uploadTask;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.fileList[0]?.originFileObj;
    setFile(selectedFile);
  };

  return (
    <div>
      {error ? (
        <>
          <InputLabelError>{label}</InputLabelError>
          <ErrorMessage>{error}</ErrorMessage>
        </>
      ) : (
        <InputLabel>{label}</InputLabel>
      )}

      {required ? <InputLabel style={{ color: "red", marginLeft: "5px" }}>*</InputLabel> : <></>}
      <Upload.Dragger
        accept={resourceType === "images" ? "image/*" : ".pdf"}
        beforeUpload={() => false}
        onChange={handleFileChange}
        fileList={file ? [file] : []}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p style={{ color: "", fontSize: "14px", fontWeight: "500" }} className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Upload.Dragger>
      <Button style={{ marginTop: "8px" }} type="primary" onClick={handleUpload} disabled={!file}>
        Upload
      </Button>
      {/* {uploadProgress > 0 && <Progress percent={uploadProgress} status="active" />} */}
    </div>
  );
};

export default ImageUploader;

import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";

export const TYPES = {
  CONFIRM_DELIVERY: "CONFIRM_DELIVERY",
  CONFIRM_DELIVERY_IN_PROGRESS: "CONFIRM_DELIVERY_IN_PROGRESS",
  CONFIRM_DELIVERY_ERROR: "CONFIRM_DELIVERY_ERROR",

  GET_ORDERS: "GET_ORDERS",
  GET_ORDERS_IN_PROGRESS: "GET_ORDERS_IN_PROGRESS",
  GET_ORDERS_ERROR: "GET_ORDERS_ERROR",

  GET_DELIVERY_ORDERS: "GET_DELIVERY_ORDERS",
  GET_DELIVERY_ORDERS_IN_PROGRESS: "GET_DELIVERY_ORDERS_IN_PROGRESS",
  GET_DELIVERY_ORDERS_ERROR: "GET_DELIVERY_ORDERS_ERROR",

  GET_ORDER_BY_ID: "GET_ORDER_BY_ID",
  GET_ORDER_BY_ID_IN_PROGRESS: "GET_ORDER_BY_ID_IN_PROGRESS",
  GET_ORDER_BY_ID_ERROR: "GET_ORDERS_ERROR_ERROR",

  CREATE_NEW_ORDER: "CREATE_NEW_ORDER",
  CREATE_NEW_ORDER_IN_PROGRESS: "CREATE_NEW_ORDER_IN_PROGRESS",
  CREATE_NEW_ORDER_ERROR: "CREATE_NEW_ORDER_ERROR",

  UPDATE_ORDER: "UPDATE_ORDER",
  UPDATE_ORDER_IN_PROGRESS: "UPDATE_ORDER_IN_PROGRESS",
  UPDATE_ORDER_ERROR: "UPDATE_ORDER_ERROR",

  GET_ANALYTICS: "GET_ANALYTICS",
  GET_ANALYTICS_IN_PROGRESS: "GET_ANALYTICS_IN_PROGRESS",
  GET_ANALYTICS_ERROR: "GET_ANALYTICS_ERROR",

  GET_ORDERS_PRODUCTION: "GET_ORDERS_PRODUCTION",
  GET_ORDERS_PRODUCTION_IN_PROGRESS: "GET_ORDERS_PRODUCTION_IN_PROGRESS",
  GET_ORDERS_PRODUCTION_ERROR: "GET_ORDERS_PRODUCTION_ERROR"
};

export function confirmOrderDelivery(order, id) {
  return function (dispatch) {
    dispatch({ type: TYPES.CONFIRM_DELIVERY_IN_PROGRESS });
    axiosInstance
      .put(`/api/orders/confirm-delivery/${id}`, order)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Delivery Confirmed Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        }).then((result) => {
          window.location.reload();
        });

        dispatch({
          type: TYPES.CONFIRM_DELIVERY,
          payload: response.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CONFIRM_DELIVERY_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Confirm Delivery"
          }
        });
      });
  };
}

export function getAnalytics() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ANALYTICS_IN_PROGRESS });
    axiosInstance
      .get(`/api/statistics`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ANALYTICS,
          payload: res?.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ANALYTICS_ERROR,
          payload: err?.response?.data || { message: "Failed to get Analytics" }
        });
      });
  };
}

export function getOrdersProduction(date = "") {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ORDERS_PRODUCTION_IN_PROGRESS });
    axiosInstance
      .get(`/api/production/orders/?date=${date}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ORDERS_PRODUCTION,
          payload: res?.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ORDERS_PRODUCTION_ERROR,
          payload: err?.response?.data || { message: "Failed to get Orders" }
        });
      });
  };
}

export function getOrders(date = "", withDetails = false) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ORDERS_IN_PROGRESS });
    axiosInstance
      .get(`/api/orders/${date}?withDetails=${withDetails}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ORDERS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_ORDERS_ERROR,
          payload: err?.response?.data || { message: "Failed to get Orders" }
        });
      });
  };
}

export function getOrderByCategoryAndLocation(status = "CONFIRMED", date) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_DELIVERY_ORDERS_IN_PROGRESS });
    axiosInstance
      .get(`/api/orderByCategoryAndLocation/?status=${status}&date=${date}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_DELIVERY_ORDERS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_DELIVERY_ORDERS_ERROR,
          payload: err?.response?.data || { message: "Failed to get Orders" }
        });
      });
  };
}

export function getOrderById(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ORDER_BY_ID_IN_PROGRESS });
    axiosInstance
      .get(`/api/order/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ORDER_BY_ID,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_ORDER_BY_ID_ERROR,
          payload: err?.response?.data || { message: "Failed to get Order" }
        });
      });
  };
}

export function createOrder(order) {
  return function (dispatch) {
    dispatch({ type: TYPES.CREATE_NEW_ORDER_IN_PROGRESS });
    axiosInstance
      .post("/api/orders/create", order)
      .then((response) => {
        dispatch({
          type: TYPES.CREATE_NEW_ORDER,
          payload: response
        });

        if (response?.data?.status === "CONFIRMED") {
          Swal.fire({
            icon: "success",
            title: "Your order has been saved and confirmed successfully",
            confirmButtonColor: "#4096ff",
            confirmButtonText: "Done"
          }).then((result) => {
            window.location.replace(`/order/view/${response.data._id}`);
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Your order has been saved successfully",
            text: "Your order has been saved, but it has not been confirmed yet. Please click on (Next) below to proceed with the confirmation process !",
            confirmButtonColor: "#4096ff",
            confirmButtonText: "Next"
          }).then((result) => {
            window.location.replace(`/order/view/${response.data._id}`);
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CREATE_NEW_ORDER_ERROR,
          payload: err?.response?.data || {
            message: "Failed to create new order"
          }
        });
        Swal.fire({
          icon: "error",
          title: "Error creating order.",
          text: err?.response?.data?.message || "Failed to create new order",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
      });
  };
}

export function editOrder(order, id, refresh = false) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_ORDER_IN_PROGRESS });
    axiosInstance
      .put(`/api/orders/update/${id}`, order)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Order Updated Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        }).then((result) => {
          if (refresh) window.location.reload();
          // window.location.replace(`/order/view/${response.data._id}`);
        });

        dispatch({
          type: TYPES.UPDATE_ORDER,
          payload: response.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_ORDER_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Edit Order"
          }
        });
      });
  };
}

export function approveOrder(id, values) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_ORDER_IN_PROGRESS });
    axiosInstance
      .put(`/api/orders/approve/${id}`, values)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Order Confirmed Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });

        dispatch({
          type: TYPES.UPDATE_ORDER,
          payload: response.data
        });
        window.location.replace("/orders/");
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_ORDER_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Approve Order"
          }
        });
      });
  };
}

export function rejectOrder(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_ORDER_IN_PROGRESS });
    axiosInstance
      .put(`/api/orders/reject/${id}`)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Order Canceled Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });

        dispatch({
          type: TYPES.UPDATE_ORDER,
          payload: response.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_ORDER_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Reject Order"
          }
        });

        Swal.fire({
          icon: "error",
          title: "Error Canceling Order.",
          text: err?.response?.data?.message || "Failed to Reject Order",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
      });
  };
}

import axiosInstance from "../../helpers/axiosInstance";
import { urlPrepare } from "../../helpers";
import { message } from "antd";
import Swal from "sweetalert2";

export const TYPES = {
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_IN_PROGRESS: "CHANGE_PASSWORD_IN_PROGRESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",

  GET_USERS_BY_ORG: "GET_USERS_BY_ORG",
  GET_USERS_BY_ORG_IN_PROGRESS: "GET_USERS_BY_ORG_IN_PROGRESS",
  GET_USERS_BY_ORG_ERROR: "GET_USERS_BY_ORG_ERROR",

  DELETE_USER: "DELETE_USER",
  DELETE_USER_IN_PROGRESS: "DELETE_USER_IN_PROGRESS",
  DELETE_USER_ERROR: "DELETE_USER_ERROR",

  ACTIVATE_USER: "ACTIVATE_USER",
  USER_NOT_LOGGEDIN: "USER_NOT_LOGGEDIN",

  GET_USER: "GET_USER",
  PAM_NEW_INVESTMENT: "PAM_NEW_INVESTMENT",
  PAM_NEW_INVESTMENT_IN_PROGRESS: "PAM_NEW_INVESTMENT_IN_PROGRESS",
  PAM_NEW_INVESTMENT_ERROR: "PAM_NEW_INVESTMENT_ERROR",

  GET_USERS: "GET_USERS",
  GET_USERS_ROLE: "GET_USERS_ROLE",
  GET_USERS_BY_ROLE_IN_PROGRESS: "GET_USERS_BY_ROLE_IN_PROGRESS",
  GET_USERS_ERROR: "GET_USERS_ERROR",

  GET_MANAGERS_BY_SUP_ID: "GET_MANAGERS_BY_SUP_ID",
  GET_MANAGERS_BY_SUP_ID_IN_PROGRESS: "GET_MANAGERS_BY_SUP_ID_IN_PROGRESS",
  GET_MANAGERS_BY_SUP_ID_ERROR: "GET_MANAGERS_BY_SUP_ID_ERROR",

  ADD_MANAGER: "ADD_MANAGER",
  ADD_MANAGER_IN_PROGRESS: "ADD_MANAGER_IN_PROGRESS",
  ADD_MANAGER_ERROR: "ADD_MANAGER_ERROR",

  GET_USER_BY_ROLE_ERROR: "GET_USER_BY_ROLE_ERROR",

  ADD_USER: "ADD_USER",
  ADD_USER_IN_PROGRESS: "ADD_USER_IN_PROGRESS",
  ADD_USER_ERROR: "ADD_USER_ERROR",

  GET_USER_BY_ID: "GET_USER_BY_ID",
  GET_USER_BY_ID_IN_PROGRESS: "GET_USER_BY_ID_IN_PROGRESS",
  GET_USER_BY_ID_ERROR: "GET_USER_BY_ID_ERROR",

  ACTIVATE_USER: "ACTIVATE_USER",
  ACTIVATE_USER_IN_PROGRESS: "ACTIVATE_USER_IN_PROGRESS",
  ACTIVATE_USER_ERROR: "ACTIVATE_USER_ERROR",

  DEACTIVATE_USER: "DEACTIVATE_USER",
  DEACTIVATE_USER_IN_PROGRESS: "DEACTIVATE_USER_IN_PROGRESS",
  DEACTIVATE_USER_ERROR: "DEACTIVATE_USER_ERROR",

  RESET_USER_PASSWORD: "RESET_USER_PASSWORD",
  RESET_USER_PASSWORD_IN_PROGRESS: "RESET_USER_PASSWORD_IN_PROGRESS",
  RESET_USER_PASSWORD_ERROR: "RESET_USER_PASSWORD_ERROR",

  TRIGGER_RESET_PASSWORD: "TRIGGER_RESET_PASSWORD",
  TRIGGER_RESET_PASSWORD_IN_PROGRESS: "TRIGGER_RESET_PASSWORD_IN_PROGRESS",
  TRIGGER_RESET_PASSWORD_ERROR: "TRIGGER_RESET_PASSWORD_ERROR",

  GET_CRMBYCLIENTID: "GET_CRMBYCLIENTID",
  GET_CRMBYCLIENTIDERROR: "GET_CRMBYCLIENTIDERROR",

  ASSIGN_CLIENTS_TO_MANAGER: "ASSIGN_CLIENTS_TO_MANAGER",
  ASSIGN_CLIENTS_TO_MANAGER_IN_PROGRESS: "ASSIGN_CLIENTS_TO_MANAGER_IN_PROGRESS",
  ASSIGN_CLIENTS_TO_MANAGER_ERROR: "ASSIGN_CLIENTS_TO_MANAGER_ERROR",

  ASSIGN_USERERROR: "ASSIGN_USERERROR",

  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_IN_PROGRESS: "UPDATE_USER_IN_PROGRESS",
  UPDATE_USER_ERROR: "UPDATE_USER_ERROR"
};

export function addManager(newManager, id) {
  return function (dispatch) {
    dispatch({ type: TYPES.ADD_MANAGER_IN_PROGRESS });
    return axiosInstance
      .post(`/managers/create/${id}`, newManager)
      .then((res) => {
        dispatch({
          type: TYPES.ADD_MANAGER,
          payload: res.data
        });
        Swal.fire({
          icon: "success",
          title: "Manager created successfully!",
          showConfirmButton: false,
          timer: 2500
        });
      })
      .catch((err) => {
        const errorMsg = err.response ? err.response.data.message : "Cannot create manager!";
        dispatch({ type: TYPES.ADD_MANAGER_ERROR });
        Swal.fire({
          icon: "error",
          title: errorMsg,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2500
        });
      });
  };
}

export function getUser() {
  return function (dispatch) {
    axiosInstance
      .get(process.env.REACT_APP_USER_ME)
      .then((res) => {
        dispatch({
          type: TYPES.GET_USER,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.USER_NOT_LOGGEDIN
        });
      });
  };
}

export function getUserById(id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.GET_USER_BY_ID_IN_PROGRESS
    });
    axiosInstance
      .get(`/api/user/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_USER_BY_ID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_USER_BY_ID_ERROR
        });
      });
  };
}

export function getUsersByRole(role) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_USERS_BY_ROLE_IN_PROGRESS });
    return axiosInstance
      .get(`/api/users/${role}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_USERS_ROLE,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_USER_BY_ROLE_ERROR
        });
      });
  };
}

export function getUsersByOrg(org) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_USERS_BY_ORG_IN_PROGRESS });
    return axiosInstance
      .get(`/api/org/${org}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_USERS_BY_ORG,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_USERS_BY_ORG_ERROR
        });
      });
  };
}
export function getManagersBySupId(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_USERS_BY_ROLE_IN_PROGRESS });
    return axiosInstance
      .get(`/api/managers/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_USERS_ROLE,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_USER_BY_ROLE_ERROR
        });
      });
  };
}

export function getUsers() {
  return function (dispatch) {
    axiosInstance
      .get(process.env.REACT_APP_USERS)
      .then((res) => {
        dispatch({
          type: TYPES.GET_USERS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_USERS_ERROR
        });
      });
  };
}

export function addUser(newUser) {
  return function (dispatch) {
    dispatch({ type: TYPES.ADD_USER_IN_PROGRESS });
    return axiosInstance
      .post("/users/signup", newUser)
      .then((res) => {
        dispatch({
          type: TYPES.ADD_USER,
          payload: res.data
        });
        console.log({ res });
        Swal.fire({
          icon: "success",
          title: res?.data?.activated
            ? "The user has been successfully registered"
            : "The user has been successfully registered, but their account awaits confirmation from catering managers.",
          showConfirmButton: false,
          timer: 2500
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ADD_USER_ERROR
        });
        const errorMsg = err.response ? err.response.data.message : "Cannot add user!";
        Swal.fire({
          icon: "error",
          title: errorMsg,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2500
        });
      });
  };
}

export function updateUser(user, id) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_USER_IN_PROGRESS });
    return axiosInstance
      .put(`/api/user/${id}`, user)
      .then((res) => {
        dispatch({
          type: TYPES.UPDATE_USER,
          payload: res.data
        });
        Swal.fire({
          icon: "success",
          title: "User updated successfully!",
          showConfirmButton: false,
          timer: 2500
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_USER_ERROR
        });
        const errorMsg = err.response ? err.response.data.message : "Cannot update user!";
        Swal.fire({
          icon: "error",
          title: errorMsg,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2500
        });
        throw err;
      });
  };
}

export function triggerResetPassword(email) {
  return function (dispatch) {
    dispatch({ type: TYPES.TRIGGER_RESET_PASSWORD_IN_PROGRESS });
    return axiosInstance
      .post(`/api/forgotPassword/`, email)
      .then((res) => {
        dispatch({
          type: TYPES.TRIGGER_RESET_PASSWORD,
          payload: res.data
        });
        Swal.fire({
          icon: "success",
          title: "Password reset link sent successfully!",
          showConfirmButton: false,
          timer: 2500
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.TRIGGER_RESET_PASSWORD_ERROR
        });
        const errorMsg = err.response
          ? err.response.data.message
          : "Cannot send reset password link!";
        Swal.fire({
          icon: "error",
          title: errorMsg,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2500
        });
      });
  };
}

export function resetPassword(token, newPassword) {
  return function (dispatch) {
    dispatch({ type: TYPES.RESET_USER_PASSWORD_IN_PROGRESS });
    return axiosInstance
      .post(`/api/reset-password/`, { newPassword, token })
      .then((res) => {
        dispatch({
          type: TYPES.RESET_USER_PASSWORD,
          payload: res.data
        });
        Swal.fire({
          icon: "success",
          title: "Password reset successfully!",
          showConfirmButton: false,
          timer: 2500
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.RESET_USER_PASSWORD_ERROR
        });
        const errorMsg = err.response ? err.response.data.message : "Cannot reset password!";
        Swal.fire({
          icon: "error",
          title: errorMsg,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2500
        });
      });
  };
}

export function changePassword(body) {
  return function (dispatch) {
    dispatch({ type: TYPES.CHANGE_PASSWORD_IN_PROGRESS });
    return axiosInstance
      .post(`/api/user/change-password/`, body)
      .then((res) => {
        dispatch({
          type: TYPES.CHANGE_PASSWORD,
          payload: res.data
        });
        Swal.fire({
          icon: "success",
          title: "Password changed successfully!",
          showConfirmButton: false,
          timer: 2500
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CHANGE_PASSWORD_ERROR
        });
        const errorMsg = err.response ? err.response.data.message : "Cannot change password!";
        Swal.fire({
          icon: "error",
          title: errorMsg,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2500
        });
      });
  };
}

export function activateUser(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.ACTIVATE_USER_IN_PROGRESS });
    return axiosInstance
      .post(`/api/user/activate/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.ACTIVATE_USER,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ACTIVATE_USER_ERROR
        });
      });
  };
}

export function deactivateUser(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.DEACTIVATE_USER_IN_PROGRESS });
    return axiosInstance
      .post(`/api/user/deactivate/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.DEACTIVATE_USER,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.DEACTIVATE_USER_ERROR
        });
      });
  };
}

export function deleteUser(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.DELETE_USER_IN_PROGRESS });
    return axiosInstance
      .delete(`/api/user/delete/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.DELETE_USER,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.DELETE_USER_ERROR
        });
      });
  };
}

export function assignClientsToManager(values) {
  return function (dispatch) {
    dispatch({ type: TYPES.ASSIGN_CLIENTS_TO_MANAGER_IN_PROGRESS });
    return axiosInstance
      .post(`${process.env.REACT_APP_ASSIGN_CLIENTS_TO_MANAGER}`, values)
      .then((res) => {
        dispatch({
          type: TYPES.ASSIGN_CLIENTS_TO_MANAGER,
          payload: res.data
        });
        Swal.fire({
          title: "Clients",
          text: "Clients Assigned Successfully!",
          icon: "success",
          confirmButtonText: "Close",
          timer: 2000
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ASSIGN_CLIENTS_TO_MANAGER_ERROR
        });
        Swal.fire({
          title: "Clients",
          text: err.message,
          type: "error"
        });
      });
  };
}

import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Card, Button, Space } from "antd";
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AntSelectInput } from "components/AntSelectInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { AntInput } from "components/AntInput";
import { registerFromLink } from "redux/links/actions";
import ImageUploader from "features/RAHAL/Plats/Components/upload.jsx";
import { countries } from "constants/x";
import QRCode from "react-qr-code";
import Invitation from "../../assets/invitation.png";
import html2canvas from "html2canvas";

import styled from "styled-components";

const HiddenContainer = styled.div`
  position: absolute;
  left: -9999px;
  top: -9999px;
`;

const InvitationImage = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 66.67%; // This assumes a 3:2 aspect ratio. Adjust if needed.
  position: relative;
  background: ${(props) =>
    props.backgroundImage
      ? `url(${props.backgroundImage}) center center / contain no-repeat`
      : "#f0f2f5"};
  color: ${(props) => (props.backgroundImage ? "white" : "black")};
  font-family: Arial, sans-serif;
`;

const InvitationContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const QRCodeContainer = styled.div`
  position: absolute;
  top: ${(props) => props.topPosition}%;
  left: 50%;
  transform: translateX(-50%);
`;

const InvitationText = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const DownloadButton = styled(Button)`
  margin-top: 20px;
`;

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  category: yup.string().required("Required"),
  country: yup.string().required("Required"),
  email: yup.string().email("invalid email").nullable(),
  title: yup.string(),
  phoneNumber: yup.string(),
  organization: yup.string(),
  occupation: yup.string(),
  photo: yup.string().nullable()
});

const RegistrationForm = ({ link }) => {
  const dispatch = useDispatch();
  const { registerIsLoading, attendee } = useSelector((state) => state.links);
  const containerRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 600, height: 400 });

  // Customize these values as needed
  const qrCodeSize = 300; // Size of the QR code in pixels
  const qrCodeTopPosition = 70; // Vertical position of the QR code in percentage

  useEffect(() => {
    // Check if the Invitation image can be loaded
    const img = new Image();
    img.onload = () => {
      setBackgroundImage(Invitation);
      setImageDimensions({ width: img.width, height: img.height });
    };
    img.onerror = () => setBackgroundImage(null);
    img.src = Invitation;
  }, []);

  const handleDownload = () => {
    if (containerRef.current) {
      html2canvas(containerRef.current, {
        scale: 2,
        width: imageDimensions.width,
        height: imageDimensions.height
      }).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = image;
        link.download = `${attendee?.firstName || "x"}_${attendee?.lastName || "x"}_invitation.png`;
        link.click();
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      category: link?.category || "",
      title: "",
      country: link?.country || null,
      email: "",
      phoneNumber: "",
      organization: "",
      occupation: "",
      link: link?._id || "",
      photo: null
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(registerFromLink(link?._id, values));
      formik.setSubmitting(false);
    }
  });

  const { handleSubmit, handleChange, handleBlur, errors, touched, setFieldValue, values } = formik;

  return (
    <Card
      title="Register"
      extra={[
        <Button
          disabled={!(formik.isValid && formik.dirty) || registerIsLoading}
          style={{ float: "right" }}
          icon={registerIsLoading ? <LoadingOutlined /> : <SaveOutlined />}
          onClick={handleSubmit}
          type="primary"
          loading={formik.isSubmitting}
        >
          Submit
        </Button>
      ]}
    >
      <Row gutter={[8, 8]}>
        {attendee && (
          <HiddenContainer ref={containerRef}>
            <InvitationImage
              backgroundImage={backgroundImage}
              style={{
                width: `${imageDimensions.width}px`,
                height: `${imageDimensions.height}px`,
                paddingBottom: 0
              }}
            >
              <InvitationContent>
                <QRCodeContainer topPosition={qrCodeTopPosition}>
                  <QRCode
                    style={{ padding: "8px" }}
                    bgColor="#00000000"
                    value={attendee?._id}
                    size={qrCodeSize}
                  />
                </QRCodeContainer>
              </InvitationContent>

              <InvitationContent>
                <QRCodeContainer topPosition={85}>
                  <span
                    style={{
                      color: "black",
                      fontSize: "30px",
                      fontWeight: "600"
                    }}
                  >
                    {attendee?.firstName} {attendee?.lastName} - {attendee?._id}
                  </span>
                </QRCodeContainer>
              </InvitationContent>
            </InvitationImage>
          </HiddenContainer>
        )}

        {/* <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }} span={24}>
          <Button size="large" disabled={!attendee} onClick={handleDownload} type="primary">
            Télécharger l'invitation
          </Button>
        </Col> */}

        {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <ImageUploader
            values={values}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            fieldName={"photo"}
            resourceType={"images"}
            label="Photo personnelle"
            required
            error={touched?.photo ? errors?.photo : ""}
          />
        </Col> */}

        {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {values?.photo ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src={values?.photo} width={"120px"} />
            </div>
          ) : (
            <div></div>
          )}
        </Col> */}

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <AntInput
            defaultValue={values.firstName}
            style={{ width: "100%" }}
            label="Prénom"
            placeholder="Prénom"
            name="firstName"
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.firstName ? errors.firstName : ""}
            required
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <AntInput
            defaultValue={values.lastName}
            style={{ width: "100%" }}
            label="Nom de famille"
            placeholder="Nom de famille"
            name="lastName"
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.lastName ? errors.lastName : ""}
            required
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <AntSelectInput
            allowClear
            showSearch
            defaultValue={values.country}
            style={{ width: "100%" }}
            label="Pays"
            placeholder="Pays"
            name="country"
            size="large"
            onChange={(e) => {
              setFieldValue("country", e);
            }}
            options={countries("16")?.map((country) => ({
              label: (
                <Space>
                  <img src={country.icon} />
                  <>{country.name}</>
                </Space>
              ),
              value: country.name
            }))}
            error={touched.country ? errors.country : ""}
            required
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <AntInput
            defaultValue={values.title}
            style={{ width: "100%" }}
            label="Titre"
            placeholder="Titre"
            name="title"
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.title ? errors.title : ""}
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <AntInput
            defaultValue={values.email}
            style={{ width: "100%" }}
            label="Email"
            placeholder="Email"
            name="email"
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email ? errors.email : ""}
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <AntInput
            defaultValue={values.phoneNumber}
            style={{ width: "100%" }}
            label="Numéro de téléphone"
            placeholder="Numéro de téléphone"
            name="phoneNumber"
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.phoneNumber ? errors.phoneNumber : ""}
          />
        </Col>

        {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <AntInput
            defaultValue={values.organization}
            style={{ width: "100%" }}
            label="Organisation"
            placeholder="Organisation"
            name="organization"
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.organization ? errors.organization : ""}
          />
        </Col> */}

        {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <AntInput
            defaultValue={values.occupation}
            style={{ width: "100%" }}
            label="Occupation"
            placeholder="Occupation"
            name="occupation"
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.occupation ? errors.occupation : ""}
          />
        </Col> */}
      </Row>
    </Card>
  );
};

export default RegistrationForm;

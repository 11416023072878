import { TYPES } from "./actions";

let initState = {
  category: {},
  getCategoryByIdIsLoading: false,
  getCategoryByIdError: null,

  createCategoryIsLoading: false,
  createCategoryError: null,

  categories: [],
  getCategoriesIsLoading: false,
  getCategoriesError: null,

  updateCategoryIsLoading: false,
  updateCategoryError: null,

  deleteCategoryIsLoading: false,
  deleteCategoryError: null
};

export function categoriesAsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.UPDATE_CATEGORY:
      return {
        ...state,
        category: action.payload,
        updateCategoryIsLoading: false
      };
    case TYPES.UPDATE_CATEGORY_IN_PROGRESS:
      return {
        ...state,
        updateCategoryIsLoading: true
      };
    case TYPES.UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        updateCategoryIsLoading: false,
        updateCategoryError: action.payload
      };

    case TYPES.GET_CATEGORY_BY_ID:
      return {
        ...state,
        category: action.payload,
        getCategoryByIdIsLoading: false
      };
    case TYPES.GET_CATEGORY_BY_ID_IN_PROGRESS:
      return {
        ...state,
        getCategoryByIdIsLoading: true
      };
    case TYPES.GET_CATEGORY_BY_ID_ERROR:
      return {
        ...state,
        getCategoryByIdIsLoading: false,
        getCategoryByIdError: action.payload
      };

    case TYPES.CREATE_NEW_CATEGORY:
      return {
        ...state,
        category: action.payload,
        createCategoryIsLoading: false
      };
    case TYPES.CREATE_NEW_CATEGORY_IN_PROGRESS:
      return {
        ...state,
        createCategoryIsLoading: true
      };
    case TYPES.CREATE_NEW_CATEGORY_ERROR:
      return {
        ...state,
        createCategoryIsLoading: false,
        createCategoryError: action.payload
      };

    case TYPES.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        getCategoriesIsLoading: false
      };
    case TYPES.GET_CATEGORIES_IN_PROGRESS:
      return {
        ...state,
        getCategoriesIsLoading: true
      };
    case TYPES.GET_CATEGORIES_ERROR:
      return {
        ...state,
        getCategoriesIsLoading: false,
        getCategoriesError: action.payload
      };

    case TYPES.DELETE_CATEGORY:
      const newPlats = state?.categories?.filter((category) => {
        return !action?.payload?.includes(category?._id);
      });

      return {
        ...state,
        categories: newPlats,
        deleteCategoryIsLoading: false
      };
    case TYPES.DELETE_CATEGORY_IN_PROGRESS:
      return {
        ...state,
        deleteCategoryIsLoading: true
      };
    case TYPES.DELETE_CATEGORY_ERROR:
      return {
        ...state,
        deleteCategoryIsLoading: false,
        deleteCategoryError: action.payload
      };
    default:
      return state;
  }
}

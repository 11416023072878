import React from "react";
import "./style.css";

export const Spinner = (props) => (
  <div
    className="spinner"
    style={Object.assign({ margin: "100px auto" }, props.style)}
  >
    <div className="rect1"></div>
    <div className="rect2"></div>
    <div className="rect3"></div>
    <div className="rect4"></div>
    <div className="rect5"></div>
  </div>
);

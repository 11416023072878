import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";

export const TYPES = {
  VERIFY_LINK: "VERIFY_LINK",
  VERIFY_LINK_IN_PROGRESS: "VERIFY_LINK_IN_PROGRESS",
  VERIFY_LINK_ERROR: "VERIFY_LINK_ERROR",

  REGISTER_FROM_LINK: "REGISTER_FROM_LINK",
  REGISTER_FROM_LINK_IN_PROGRESS: "REGISTER_FROM_LINK_IN_PROGRESS",
  REGISTER_FROM_LINK_ERROR: "REGISTER_FROM_LINK_ERROR",

  GET_LINKS: "GET_LINKS",
  GET_LINKS_IN_PROGRESS: "GET_LINKS_IN_PROGRESS",
  GET_LINKS_ERROR: "GET_LINKS_ERROR",

  GET_LINKS_BY_MANAGER_ID: "GET_LINKS_BY_MANAGER_ID",
  GET_LINKS_BY_MANAGER_ID_IN_PROGRESS: "GET_LINKS_BY_MANAGER_ID_IN_PROGRESS",
  GET_LINKS_BY_MANAGER_ID_ERROR: "GET_LINKS_BY_MANAGER_ID_ERROR",

  GET_LINK_BY_ID: "GET_LINK_BY_ID",
  GET_LINK_BY_ID_IN_PROGRESS: "GET_LINK_BY_ID_IN_PROGRESS",
  GET_LINK_BY_ID_ERROR: "GET_LINK_BY_ID_ERROR",

  GENERATE_NEW_LINK: "GENERATE_NEW_LINK",
  GENERATE_NEW_LINK_IN_PROGRESS: "GENERATE_NEW_LINK_IN_PROGRESS",
  GENERATE_NEW_LINK_ERROR: "GENERATE_NEW_LINK_ERROR",

  UPDATE_LINK: "UPDATE_LINK",
  UPDATE_LINK_IN_PROGRESS: "UPDATE_LINK_IN_PROGRESS",
  UPDATE_LINK_ERROR: "UPDATE_LINK_ERROR",

  DELETE_LINK: "DELETE_LINK",
  DELETE_LINK_IN_PROGRESS: "DELETE_LINK_IN_PROGRESS",
  DELETE_LINK_ERROR: "DELETE_LINK_ERROR"
};

export function getLinksByManagerId(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_LINKS_BY_MANAGER_ID_IN_PROGRESS });
    axiosInstance
      .get(`/api/linksByManagerId/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_LINKS_BY_MANAGER_ID,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_LINKS_BY_MANAGER_ID_ERROR,
          payload: err?.response?.data || { message: "Failed to get links" }
        });
      });
  };
}

export function verifyLink(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.VERIFY_LINK_IN_PROGRESS });
    axiosInstance
      .get(`/api/verify-link/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.VERIFY_LINK,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.VERIFY_LINK_ERROR,
          payload: err?.response?.data || { message: "Failed to verify link" }
        });
      });
  };
}

export function registerFromLink(linkId, attendee) {
  return function (dispatch) {
    dispatch({ type: TYPES.REGISTER_FROM_LINK_IN_PROGRESS });
    axiosInstance
      .post(`/api/register/${linkId}`, attendee)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title:
            "Nous avons bien enregistré votre inscription. Veuillez télécharger votre invitation et la présenter à l'accueil de l'événement pour récupérer votre badge.",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        }).then((result) => {
          window.location.replace(`/invitation/${response.data._id}`);
        });

        dispatch({
          type: TYPES.REGISTER_FROM_LINK,
          payload: response?.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.REGISTER_FROM_LINK_ERROR,
          payload: err?.response?.data || {
            message: "Registration failed !"
          }
        });
        Swal.fire({
          icon: "error",
          title: `Registration failed ! The quota for registrations has been exceeded.`,
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
      });
  };
}

export function getLinks() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_LINKS_IN_PROGRESS });
    axiosInstance
      .get("/api/links")
      .then((res) => {
        dispatch({
          type: TYPES.GET_LINKS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_LINKS_ERROR,
          payload: err?.response?.data || { message: "Failed to get links" }
        });
      });
  };
}

export function getLinksByUserId(userId) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_LINKS_IN_PROGRESS });
    axiosInstance
      .get(`/api/links/user/${userId}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_LINKS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_LINKS_ERROR,
          payload: err?.response?.data || { message: "Failed to get links" }
        });
      });
  };
}

export function getLinkById(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_LINK_BY_ID_IN_PROGRESS });
    axiosInstance
      .get(`/api/link/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_LINK_BY_ID,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_LINK_BY_ID_ERROR,
          payload: err?.response?.data || { message: "Failed to get link" }
        });
      });
  };
}

export function generateNewLink(link) {
  return function (dispatch) {
    dispatch({ type: TYPES.GENERATE_NEW_LINK_IN_PROGRESS });
    axiosInstance
      .post("/api/link", link)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Link Generated Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        }).then((result) => {
          console.log({ result });
        });

        dispatch({
          type: TYPES.GENERATE_NEW_LINK,
          payload: response?.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GENERATE_NEW_LINK_ERROR,
          payload: err?.response?.data || {
            message: "Failed to generate new link"
          }
        });
        Swal.fire({
          icon: "error",
          title: "Link with the same category already exists for this Attendees Manager !",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
      });
  };
}

export function updateLink(link, id) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_LINK_IN_PROGRESS });
    axiosInstance
      .put(`/api/link/${id}`, link)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Link Updated Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        }).then((result) => {
          console.log({ result });
        });

        dispatch({
          type: TYPES.UPDATE_LINK,
          payload: response?.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_LINK_ERROR,
          payload: err?.response?.data || {
            message: "Failed to update link"
          }
        });
        Swal.fire({
          icon: "error",
          title: "Link with the same category already exists for this Attendees Manager !",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
      });
  };
}

export function deleteLink(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.DELETE_LINK_IN_PROGRESS });
    axiosInstance
      .delete(`/api/link/${id}`)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Link Deleted Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
        dispatch({
          type: TYPES.DELETE_LINK,
          payload: [id]
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.DELETE_LINK_ERROR,
          payload: err?.response?.data || {
            message: "Failed to delete link"
          }
        });
      });
  };
}

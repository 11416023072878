export const countries = (size = "24") => {
  return [
    {
      name: "Afghanistan",
      code: "AF",
      icon: `https://flagsapi.com/AF/shiny/${size}.png`
    },

    {
      name: "Albania",
      code: "AL",
      icon: `https://flagsapi.com/AL/shiny/${size}.png`
    },
    {
      name: "Algeria",
      code: "DZ",
      icon: `https://flagsapi.com/DZ/shiny/${size}.png`
    },
    {
      name: "AndorrA",
      code: "AD",
      icon: `https://flagsapi.com/AD/shiny/${size}.png`
    },
    {
      name: "Angola",
      code: "AO",
      icon: `https://flagsapi.com/AO/shiny/${size}.png`
    },

    {
      name: "Argentina",
      code: "AR",
      icon: `https://flagsapi.com/AR/shiny/${size}.png`
    },
    {
      name: "Armenia",
      code: "AM",
      icon: `https://flagsapi.com/AM/shiny/${size}.png`
    },
    {
      name: "Australia",
      code: "AU",
      icon: `https://flagsapi.com/AU/shiny/${size}.png`
    },
    {
      name: "Austria",
      code: "AT",
      icon: `https://flagsapi.com/AT/shiny/${size}.png`
    },
    {
      name: "Azerbaijan",
      code: "AZ",
      icon: `https://flagsapi.com/AZ/shiny/${size}.png`
    },

    {
      name: "Bahrain",
      code: "BH",
      icon: `https://flagsapi.com/BH/shiny/${size}.png`
    },
    {
      name: "Bangladesh",
      code: "BD",
      icon: `https://flagsapi.com/BD/shiny/${size}.png`
    },

    {
      name: "Belarus",
      code: "BY",
      icon: `https://flagsapi.com/BY/shiny/${size}.png`
    },
    {
      name: "Belgium",
      code: "BE",
      icon: `https://flagsapi.com/BE/shiny/${size}.png`
    },

    {
      name: "Benin",
      code: "BJ",
      icon: `https://flagsapi.com/BJ/shiny/${size}.png`
    },

    {
      name: "Bolivia",
      code: "BO",
      icon: `https://flagsapi.com/BO/shiny/${size}.png`
    },


    {
      name: "Brazil",
      code: "BR",
      icon: `https://flagsapi.com/BR/shiny/${size}.png`
    },

    {
      name: "Bulgaria",
      code: "BG",
      icon: `https://flagsapi.com/BG/shiny/${size}.png`
    },
    {
      name: "Burkina Faso",
      code: "BF",
      icon: `https://flagsapi.com/BF/shiny/${size}.png`
    },

    {
      name: "Cambodia",
      code: "KH",
      icon: `https://flagsapi.com/KH/shiny/${size}.png`
    },
    {
      name: "Cameroon",
      code: "CM",
      icon: `https://flagsapi.com/CM/shiny/${size}.png`
    },
    {
      name: "Canada",
      code: "CA",
      icon: `https://flagsapi.com/CA/shiny/${size}.png`
    },
    {
      name: "Cape Verde",
      code: "CV",
      icon: `https://flagsapi.com/CV/shiny/${size}.png`
    },


    {
      name: "Chad",
      code: "TD",
      icon: `https://flagsapi.com/TD/shiny/${size}.png`
    },
    {
      name: "Chile",
      code: "CL",
      icon: `https://flagsapi.com/CL/shiny/${size}.png`
    },
    {
      name: "China",
      code: "CN",
      icon: `https://flagsapi.com/CN/shiny/${size}.png`
    },


    {
      name: "Colombia",
      code: "CO",
      icon: `https://flagsapi.com/CO/shiny/${size}.png`
    },
    {
      name: "Comoros",
      code: "KM",
      icon: `https://flagsapi.com/KM/shiny/${size}.png`
    },
    {
      name: "Congo",
      code: "CG",
      icon: `https://flagsapi.com/CG/shiny/${size}.png`
    },
    {
      name: "Congo, Democratic",
      code: "CD",
      icon: `https://flagsapi.com/CD/shiny/${size}.png`
    },

    {
      name: "Costa Rica",
      code: "CR",
      icon: `https://flagsapi.com/CR/shiny/${size}.png`
    },
    {
      name: "Cote D'Ivoire",
      code: "CI",
      icon: `https://flagsapi.com/CI/shiny/${size}.png`
    },
    {
      name: "Croatia",
      code: "HR",
      icon: `https://flagsapi.com/HR/shiny/${size}.png`
    },
    {
      name: "Cuba",
      code: "CU",
      icon: `https://flagsapi.com/CU/shiny/${size}.png`
    },
    {
      name: "Cyprus",
      code: "CY",
      icon: `https://flagsapi.com/CY/shiny/${size}.png`
    },
    {
      name: "Czech Republic",
      code: "CZ",
      icon: `https://flagsapi.com/CZ/shiny/${size}.png`
    },
    {
      name: "Denmark",
      code: "DK",
      icon: `https://flagsapi.com/DK/shiny/${size}.png`
    },
    {
      name: "Djibouti",
      code: "DJ",
      icon: `https://flagsapi.com/DJ/shiny/${size}.png`
    },
    {
      name: "Dominica",
      code: "DM",
      icon: `https://flagsapi.com/DM/shiny/${size}.png`
    },
    {
      name: "Dominican Republic",
      code: "DO",
      icon: `https://flagsapi.com/DO/shiny/${size}.png`
    },
    {
      name: "Ecuador",
      code: "EC",
      icon: `https://flagsapi.com/EC/shiny/${size}.png`
    },
    {
      name: "Egypt",
      code: "EG",
      icon: `https://flagsapi.com/EG/shiny/${size}.png`
    },
    {
      name: "El Salvador",
      code: "SV",
      icon: `https://flagsapi.com/SV/shiny/${size}.png`
    },
    {
      name: "Equatorial Guinea",
      code: "GQ",
      icon: `https://flagsapi.com/GQ/shiny/${size}.png`
    },
    {
      name: "Eritrea",
      code: "ER",
      icon: `https://flagsapi.com/ER/shiny/${size}.png`
    },
    {
      name: "Estonia",
      code: "EE",
      icon: `https://flagsapi.com/EE/shiny/${size}.png`
    },
    {
      name: "Ethiopia",
      code: "ET",
      icon: `https://flagsapi.com/ET/shiny/${size}.png`
    },

    {
      name: "Fiji",
      code: "FJ",
      icon: `https://flagsapi.com/FJ/shiny/${size}.png`
    },
    {
      name: "Finland",
      code: "FI",
      icon: `https://flagsapi.com/FI/shiny/${size}.png`
    },
    {
      name: "France",
      code: "FR",
      icon: `https://flagsapi.com/FR/shiny/${size}.png`
    },
    {
      name: "Gabon",
      code: "GA",
      icon: `https://flagsapi.com/GA/shiny/${size}.png`
    },
    {
      name: "Gambia",
      code: "GM",
      icon: `https://flagsapi.com/GM/shiny/${size}.png`
    },
    {
      name: "Georgia",
      code: "GE",
      icon: `https://flagsapi.com/GE/shiny/${size}.png`
    },
    {
      name: "Germany",
      code: "DE",
      icon: `https://flagsapi.com/DE/shiny/${size}.png`
    },
    {
      name: "Ghana",
      code: "GH",
      icon: `https://flagsapi.com/GH/shiny/${size}.png`
    },
    {
      name: "Gibraltar",
      code: "GI",
      icon: `https://flagsapi.com/GI/shiny/${size}.png`
    },
    {
      name: "Greece",
      code: "GR",
      icon: `https://flagsapi.com/GR/shiny/${size}.png`
    },
    {
      name: "Greenland",
      code: "GL",
      icon: `https://flagsapi.com/GL/shiny/${size}.png`
    },
    {
      name: "Guinea",
      code: "GN",
      icon: `https://flagsapi.com/GN/shiny/${size}.png`
    },
    {
      name: "Guinea-Bissau",
      code: "GW",
      icon: `https://flagsapi.com/GW/shiny/${size}.png`
    },
    {
      name: "Guyana",
      code: "GY",
      icon: `https://flagsapi.com/GY/shiny/${size}.png`
    },
    {
      name: "Haiti",
      code: "HT",
      icon: `https://flagsapi.com/HT/shiny/${size}.png`
    },
    {
      name: "Honduras",
      code: "HN",
      icon: `https://flagsapi.com/HN/shiny/${size}.png`
    },
    {
      name: "Hong Kong",
      code: "HK",
      icon: `https://flagsapi.com/HK/shiny/${size}.png`
    },
    {
      name: "Hungary",
      code: "HU",
      icon: `https://flagsapi.com/HU/shiny/${size}.png`
    },
    {
      name: "Iceland",
      code: "IS",
      icon: `https://flagsapi.com/IS/shiny/${size}.png`
    },
    {
      name: "India",
      code: "IN",
      icon: `https://flagsapi.com/IN/shiny/${size}.png`
    },
    {
      name: "Indonesia",
      code: "ID",
      icon: `https://flagsapi.com/ID/shiny/${size}.png`
    },
    {
      name: "Iran",
      code: "IR",
      icon: `https://flagsapi.com/IR/shiny/${size}.png`
    },
    {
      name: "Iraq",
      code: "IQ",
      icon: `https://flagsapi.com/IQ/shiny/${size}.png`
    },
    {
      name: "Ireland",
      code: "IE",
      icon: `https://flagsapi.com/IE/shiny/${size}.png`
    },
    {
      name: "Italy",
      code: "IT",
      icon: `https://flagsapi.com/IT/shiny/${size}.png`
    },
    {
      name: "Jamaica",
      code: "JM",
      icon: `https://flagsapi.com/JM/shiny/${size}.png`
    },
    {
      name: "Japan",
      code: "JP",
      icon: `https://flagsapi.com/JP/shiny/${size}.png`
    },
    {
      name: "Jordan",
      code: "JO",
      icon: `https://flagsapi.com/JO/shiny/${size}.png`
    },
    {
      name: "Kazakhstan",
      code: "KZ",
      icon: `https://flagsapi.com/KZ/shiny/${size}.png`
    },
    {
      name: "Kenya",
      code: "KE",
      icon: `https://flagsapi.com/KE/shiny/${size}.png`
    },
    {
      name: "North Korea",
      code: "KP",
      icon: `https://flagsapi.com/KP/shiny/${size}.png`
    },
    {
      name: "South Korea",
      code: "KR",
      icon: `https://flagsapi.com/KR/shiny/${size}.png`
    },
    {
      name: "Kuwait",
      code: "KW",
      icon: `https://flagsapi.com/KW/shiny/${size}.png`
    },
    {
      name: "Kyrgyzstan",
      code: "KG",
      icon: `https://flagsapi.com/KG/shiny/${size}.png`
    },

    {
      name: "Lebanon",
      code: "LB",
      icon: `https://flagsapi.com/LB/shiny/${size}.png`
    },

    {
      name: "Liberia",
      code: "LR",
      icon: `https://flagsapi.com/LR/shiny/${size}.png`
    },
    {
      name: "Liechtenstein",
      code: "LI",
      icon: `https://flagsapi.com/LI/shiny/${size}.png`
    },
    {
      name: "Lithuania",
      code: "LT",
      icon: `https://flagsapi.com/LT/shiny/${size}.png`
    },
    {
      name: "Luxembourg",
      code: "LU",
      icon: `https://flagsapi.com/LU/shiny/${size}.png`
    },
    {
      name: "Macao",
      code: "MO",
      icon: `https://flagsapi.com/MO/shiny/${size}.png`
    },

    {
      name: "Madagascar",
      code: "MG",
      icon: `https://flagsapi.com/MG/shiny/${size}.png`
    },
    {
      name: "Malawi",
      code: "MW",
      icon: `https://flagsapi.com/MW/shiny/${size}.png`
    },
    {
      name: "Malaysia",
      code: "MY",
      icon: `https://flagsapi.com/MY/shiny/${size}.png`
    },
    {
      name: "Maldives",
      code: "MV",
      icon: `https://flagsapi.com/MV/shiny/${size}.png`
    },
    {
      name: "Mali",
      code: "ML",
      icon: `https://flagsapi.com/ML/shiny/${size}.png`
    },
    {
      name: "Malta",
      code: "MT",
      icon: `https://flagsapi.com/MT/shiny/${size}.png`
    },
    {
      name: "Martinique",
      code: "MQ",
      icon: `https://flagsapi.com/MQ/shiny/${size}.png`
    },
    {
      name: "Mauritania",
      code: "MR",
      icon: `https://flagsapi.com/MR/shiny/${size}.png`
    },
    {
      name: "Mauritius",
      code: "MU",
      icon: `https://flagsapi.com/MU/shiny/${size}.png`
    },
    {
      name: "Mexico",
      code: "MX",
      icon: `https://flagsapi.com/MX/shiny/${size}.png`
    },

    {
      name: "Moldova",
      code: "MD",
      icon: `https://flagsapi.com/MD/shiny/${size}.png`
    },
    {
      name: "Monaco",
      code: "MC",
      icon: `https://flagsapi.com/MC/shiny/${size}.png`
    },
    {
      name: "Mongolia",
      code: "MN",
      icon: `https://flagsapi.com/MN/shiny/${size}.png`
    },
    {
      name: "Morocco",
      code: "MA",
      icon: `https://flagsapi.com/MA/shiny/${size}.png`
    },
    {
      name: "Mozambique",
      code: "MZ",
      icon: `https://flagsapi.com/MZ/shiny/${size}.png`
    },

    {
      name: "Namibia",
      code: "NA",
      icon: `https://flagsapi.com/NA/shiny/${size}.png`
    },

    {
      name: "Nepal",
      code: "NP",
      icon: `https://flagsapi.com/NP/shiny/${size}.png`
    },
    {
      name: "Netherlands",
      code: "NL",
      icon: `https://flagsapi.com/NL/shiny/${size}.png`
    },


    {
      name: "New Zealand",
      code: "NZ",
      icon: `https://flagsapi.com/NZ/shiny/${size}.png`
    },
    {
      name: "Nicaragua",
      code: "NI",
      icon: `https://flagsapi.com/NI/shiny/${size}.png`
    },
    {
      name: "Niger",
      code: "NE",
      icon: `https://flagsapi.com/NE/shiny/${size}.png`
    },
    {
      name: "Nigeria",
      code: "NG",
      icon: `https://flagsapi.com/NG/shiny/${size}.png`
    },


    {
      name: "Norway",
      code: "NO",
      icon: `https://flagsapi.com/NO/shiny/${size}.png`
    },
    {
      name: "Oman",
      code: "OM",
      icon: `https://flagsapi.com/OM/shiny/${size}.png`
    },
    {
      name: "Pakistan",
      code: "PK",
      icon: `https://flagsapi.com/PK/shiny/${size}.png`
    },

    {
      name: "Palestine",
      code: "PS",
      icon: `https://flagsapi.com/PS/shiny/${size}.png`
    },
    {
      name: "Panama",
      code: "PA",
      icon: `https://flagsapi.com/PA/shiny/${size}.png`
    },

    {
      name: "Paraguay",
      code: "PY",
      icon: `https://flagsapi.com/PY/shiny/${size}.png`
    },
    {
      name: "Peru",
      code: "PE",
      icon: `https://flagsapi.com/PE/shiny/${size}.png`
    },
    {
      name: "Philippines",
      code: "PH",
      icon: `https://flagsapi.com/PH/shiny/${size}.png`
    },

    {
      name: "Poland",
      code: "PL",
      icon: `https://flagsapi.com/PL/shiny/${size}.png`
    },
    {
      name: "Portugal",
      code: "PT",
      icon: `https://flagsapi.com/PT/shiny/${size}.png`
    },
    {
      name: "Puerto Rico",
      code: "PR",
      icon: `https://flagsapi.com/PR/shiny/${size}.png`
    },
    {
      name: "Qatar",
      code: "QA",
      icon: `https://flagsapi.com/QA/shiny/${size}.png`
    },

    {
      name: "Romania",
      code: "RO",
      icon: `https://flagsapi.com/RO/shiny/${size}.png`
    },
    {
      name: "Russia",
      code: "RU",
      icon: `https://flagsapi.com/RU/shiny/${size}.png`
    },
    {
      name: "RWANDA",
      code: "RW",
      icon: `https://flagsapi.com/RW/shiny/${size}.png`
    },
    {
      name: "Samoa",
      code: "WS",
      icon: `https://flagsapi.com/WS/shiny/${size}.png`
    },
    {
      name: "San Marino",
      code: "SM",
      icon: `https://flagsapi.com/SM/shiny/${size}.png`
    },

    {
      name: "Saudi Arabia",
      code: "SA",
      icon: `https://flagsapi.com/SA/shiny/${size}.png`
    },
    {
      name: "Senegal",
      code: "SN",
      icon: `https://flagsapi.com/SN/shiny/${size}.png`
    },
    {
      name: "Serbia",
      code: "CS",
      icon: `https://flagsapi.com/CS/shiny/${size}.png`
    },
    {
      name: "Sierra Leone",
      code: "SL",
      icon: `https://flagsapi.com/SL/shiny/${size}.png`
    },
    {
      name: "Singapore",
      code: "SG",
      icon: `https://flagsapi.com/SG/shiny/${size}.png`
    },
    {
      name: "Slovakia",
      code: "SK",
      icon: `https://flagsapi.com/SK/shiny/${size}.png`
    },
    {
      name: "Slovenia",
      code: "SI",
      icon: `https://flagsapi.com/SI/shiny/${size}.png`
    },

    {
      name: "Somalia",
      code: "SO",
      icon: `https://flagsapi.com/SO/shiny/${size}.png`
    },
    {
      name: "South Africa",
      code: "ZA",
      icon: `https://flagsapi.com/ZA/shiny/${size}.png`
    },
    {
      name: "Spain",
      code: "ES",
      icon: `https://flagsapi.com/ES/shiny/${size}.png`
    },
    {
      name: "Sri Lanka",
      code: "LK",
      icon: `https://flagsapi.com/LK/shiny/${size}.png`
    },
    {
      name: "Sudan",
      code: "SD",
      icon: `https://flagsapi.com/SD/shiny/${size}.png`
    },
    {
      name: "Sweden",
      code: "SE",
      icon: `https://flagsapi.com/SE/shiny/${size}.png`
    },
    {
      name: "Switzerland",
      code: "CH",
      icon: `https://flagsapi.com/CH/shiny/${size}.png`
    },
    {
      name: "Syria",
      code: "SY",
      icon: `https://flagsapi.com/SY/shiny/${size}.png`
    },
    {
      name: "Taiwan",
      code: "TW",
      icon: `https://flagsapi.com/TW/shiny/${size}.png`
    },
    {
      name: "Tajikistan",
      code: "TJ",
      icon: `https://flagsapi.com/TJ/shiny/${size}.png`
    },
    {
      name: "Tanzania",
      code: "TZ",
      icon: `https://flagsapi.com/TZ/shiny/${size}.png`
    },
    {
      name: "Thailand",
      code: "TH",
      icon: `https://flagsapi.com/TH/shiny/${size}.png`
    },

    {
      name: "Togo",
      code: "TG",
      icon: `https://flagsapi.com/TG/shiny/${size}.png`
    },

    {
      name: "Tonga",
      code: "TO",
      icon: `https://flagsapi.com/TO/shiny/${size}.png`
    },

    {
      name: "Tunisia",
      code: "TN",
      icon: `https://flagsapi.com/TN/shiny/${size}.png`
    },
    {
      name: "Turkey",
      code: "TR",
      icon: `https://flagsapi.com/TR/shiny/${size}.png`
    },
    {
      name: "Turkmenistan",
      code: "TM",
      icon: `https://flagsapi.com/TM/shiny/${size}.png`
    },


    {
      name: "Uganda",
      code: "UG",
      icon: `https://flagsapi.com/UG/shiny/${size}.png`
    },
    {
      name: "Ukraine",
      code: "UA",
      icon: `https://flagsapi.com/UA/shiny/${size}.png`
    },
    {
      name: "United Arab Emirates",
      code: "AE",
      icon: `https://flagsapi.com/AE/shiny/${size}.png`
    },
    {
      name: "United Kingdom",
      code: "GB",
      icon: `https://flagsapi.com/GB/shiny/${size}.png`
    },
    {
      name: "United States",
      code: "US",
      icon: `https://flagsapi.com/US/shiny/${size}.png`
    },

    {
      name: "Uruguay",
      code: "UY",
      icon: `https://flagsapi.com/UY/shiny/${size}.png`
    },
    {
      name: "Uzbekistan",
      code: "UZ",
      icon: `https://flagsapi.com/UZ/shiny/${size}.png`
    },

    {
      name: "Venezuela",
      code: "VE",
      icon: `https://flagsapi.com/VE/shiny/${size}.png`
    },
    {
      name: "Vietnam",
      code: "VN",
      icon: `https://flagsapi.com/VN/shiny/${size}.png`
    },
    {
      name: "Yemen",
      code: "YE",
      icon: `https://flagsapi.com/YE/shiny/${size}.png`
    },
    {
      name: "Zambia",
      code: "ZM",
      icon: `https://flagsapi.com/ZM/shiny/${size}.png`
    },
    {
      name: "Zimbabwe",
      code: "ZW",
      icon: `https://flagsapi.com/ZW/shiny/${size}.png`
    }
  ];
};

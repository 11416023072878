import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Spinner } from "../components/Spinner/Spinner";


export const IsUserRedirect = ({
  isAuthenticated,
  isLoading,
  component: Component,
  loggedInPath,
  children,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return <Spinner />;
        }

        if (!isAuthenticated) {
          return <Component {...props} />;
        }
        return <Redirect to={{ pathname: loggedInPath }} />;
      }}
    />
  );
};

export const ProtectedRoute = ({
  isAuthenticated,
  isLoading,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return <Spinner />;
        }
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";

export const TYPES = {
  GET_TICKETS: "GET_TICKETS",
  GET_TICKETS_IN_PROGRESS: "GET_TICKETS_IN_PROGRESS",
  GET_TICKETS_ERROR: "GET_TICKETS_ERROR",

  GET_TICKETS_BY_USER_ID: "GET_TICKETS_BY_USER_ID",
  GET_TICKETS_BY_USER_ID_IN_PROGRESS: "GET_TICKETS_BY_USER_ID_IN_PROGRESS",
  GET_TICKETS_BY_USER_ID_ERROR: "GET_TICKETS_BY_USER_ID_ERROR",

  GET_TICKET_BY_ID: "GET_TICKET_BY_ID",
  GET_TICKET_BY_ID_IN_PROGRESS: "GET_TICKET_BY_ID_IN_PROGRESS",
  GET_TICKET_BY_ID_ERROR: "GET_TICKET_ERROR_ERROR",

  CREATE_NEW_TICKET: "CREATE_NEW_TICKET",
  CREATE_NEW_TICKET_IN_PROGRESS: "CREATE_NEW_TICKET_IN_PROGRESS",
  CREATE_NEW_TICKET_ERROR: "CREATE_NEW_TICKET_ERROR",

  UPDATE_TICKET: "UPDATE_TICKET",
  UPDATE_TICKET_IN_PROGRESS: "UPDATE_TICKET_IN_PROGRESS",
  UPDATE_TICKET_ERROR: "UPDATE_TICKET_ERROR",

  DELETE_TICKET: "DELETE_TICKET",
  DELETE_TICKET_IN_PROGRESS: "DELETE_TICKET_IN_PROGRESS",
  DELETE_TICKET_ERROR: "DELETE_TICKET_ERROR",

  ADD_COMMENT_TO_TICKET: "ADD_COMMENT_TO_TICKET",
  ADD_COMMENT_TO_TICKET_IN_PROGRESS: "ADD_COMMENT_TO_TICKET_IN_PROGRESS",
  ADD_COMMENT_TO_TICKET_ERROR: "ADD_COMMENT_TO_TICKET_ERROR",

  DELETE_COMMENT_FROM_TICKET: "DELETE_COMMENT_FROM_TICKET",
  DELETE_COMMENT_FROM_TICKET_IN_PROGRESS: "DELETE_COMMENT_FROM_TICKET_IN_PROGRESS",
  DELETE_COMMENT_FROM_TICKET_ERROR: "DELETE_COMMENT_FROM_TICKET_ERROR"
};

export function deleteComment(ticketId, commentId) {
  return function (dispatch) {
    dispatch({ type: TYPES.DELETE_COMMENT_FROM_TICKET_IN_PROGRESS });
    axiosInstance
      .delete(`/api/comment/${ticketId}/${commentId}`)
      .then((res) => {
        dispatch({
          type: TYPES.DELETE_COMMENT_FROM_TICKET,
          payload: res?.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.DELETE_COMMENT_FROM_TICKET_ERROR,
          payload: err?.response?.data || { message: "Failed to delete Comment" }
        });
      });
  };
}

export function addComment(userId, ticketId, comment) {
  return function (dispatch) {
    dispatch({ type: TYPES.ADD_COMMENT_TO_TICKET_IN_PROGRESS });
    axiosInstance
      .post(`/api/comment/${ticketId}`, { userId, comment })
      .then((res) => {
        dispatch({
          type: TYPES.ADD_COMMENT_TO_TICKET,
          payload: res?.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ADD_COMMENT_TO_TICKET_ERROR,
          payload: err?.response?.data || { message: "Failed to add Comment" }
        });
      });
  };
}

export function getTickets() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_TICKETS_IN_PROGRESS });
    axiosInstance
      .get(`/api/tickets`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_TICKETS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_TICKETS_ERROR,
          payload: err?.response?.data || { message: "Failed to get Orders" }
        });
      });
  };
}

export function getTicketsByUserId(userId) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_TICKETS_BY_USER_ID_IN_PROGRESS });
    axiosInstance
      .get(`/api/tickets/${userId}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_TICKETS_BY_USER_ID,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_TICKETS_BY_USER_ID_ERROR,
          payload: err?.response?.data || { message: "Failed to get Orders" }
        });
      });
  };
}

export function getTicketById(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_TICKET_BY_ID_IN_PROGRESS });
    axiosInstance
      .get(`/api/ticket/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_TICKET_BY_ID,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_TICKET_BY_ID_ERROR,
          payload: err?.response?.data || { message: "Failed to get Orders" }
        });
      });
  };
}

export function createNewTicket(ticket) {
  return function (dispatch) {
    dispatch({ type: TYPES.CREATE_NEW_TICKET_IN_PROGRESS });
    axiosInstance
      .post(`/api/tickets/create`, ticket)
      .then((res) => {
        dispatch({
          type: TYPES.CREATE_NEW_TICKET,
          payload: res?.data
        });
        Swal.fire({
          icon: "success",
          title: "Ticket created successfully",
          showConfirmButton: false,
          timer: 1500
        });
      })
      .then(() => {
        window.location.replace("/tickets");
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.CREATE_NEW_TICKET_ERROR,
          payload: err?.response?.data || { message: "Failed to create Ticket" }
        });
      });
  };
}

export function updateTicket(id, ticket) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_TICKET_IN_PROGRESS });
    axiosInstance
      .put(`/api/ticket/${id}`, ticket)
      .then((res) => {
        dispatch({
          type: TYPES.UPDATE_TICKET,
          payload: res?.data
        });
        Swal.fire({
          icon: "success",
          title: "Ticket updated successfully",
          showConfirmButton: false,
          timer: 1500
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.UPDATE_TICKET_ERROR,
          payload: err?.response?.data || { message: "Failed to update Ticket" }
        });
      });
  };
}

export function deleteTicket(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.DELETE_TICKET_IN_PROGRESS });
    axiosInstance
      .delete(`/api/ticket/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.DELETE_TICKET,
          payload: res?.data
        });
        Swal.fire({
          icon: "success",
          title: "Ticket deleted successfully",
          showConfirmButton: false,
          timer: 1500
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.DELETE_TICKET_ERROR,
          payload: err?.response?.data || { message: "Failed to delete Ticket" }
        });
      });
  };
}

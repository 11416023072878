import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { toasterReducer } from "./redux/toaster/reducer";
import { userReducer } from "./redux/users/reducer";
import { authReducer } from "./redux/auth/reducer";
import { rahalOrdersAsReducer } from "./redux/rahalOrders/reducer";
import { eventAsReducer } from "./redux/event/reducer";
import { rahalPlatsAsReducer } from "./redux/plats/reducer";
import { accountsReducer } from "./redux/accounts/reducer";
import { menuSettingsAsReducer } from "./redux/menuSettings/reducer";
import { rahalTicketsAsReducer } from "redux/tickets/reducer";
import { categoriesAsReducer } from "redux/categories/reducer";
import { badgesAsReducer } from "redux/badges/reducer";
import { attendeesAsReducer } from "redux/attendees/reducer";
import { linksAsReducer } from "redux/links/reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {};

const middleware = [thunk];

const allReducers = combineReducers({
  toaster: toasterReducer,
  user: userReducer,
  auth: authReducer,
  eventAs: eventAsReducer,
  rahalOrders: rahalOrdersAsReducer,
  plats: rahalPlatsAsReducer,
  accounts: accountsReducer,
  menuSettings: menuSettingsAsReducer,
  tickets: rahalTicketsAsReducer,
  categories: categoriesAsReducer,
  badges: badgesAsReducer,
  attendees: attendeesAsReducer,
  links: linksAsReducer
});

const store = createStore(
  allReducers,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;

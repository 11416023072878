import React from "react";
import { Select } from "antd";
import { InputLabel, InputLabelError, ErrorMessage } from "./styles";

export const AntSelectInput = ({ ...props }) => {
  return (
    <>
      {props.error ? (
        <>
          <InputLabelError>{props.label}</InputLabelError>
          <ErrorMessage>{props.error}</ErrorMessage>
        </>
      ) : (
        <InputLabel>{props.label}</InputLabel>
      )}

      {props.required ? (
        <InputLabel style={{ color: "red", marginLeft: "5px" }}>*</InputLabel>
      ) : (
        <></>
      )}
      <Select {...props} />
    </>
  );
};

import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";

export const TYPES = {
  GET_PLATS: "GET_PLATS",
  GET_PLATS_IN_PROGRESS: "GET_PLATS_IN_PROGRESS",
  GET_PLATS_ERROR: "GET_PLATS_ERROR",

  GET_PLAT_BY_ID: "GET_PLAT_BY_ID",
  GET_PLAT_BY_ID_IN_PROGRESS: "GET_PLAT_BY_ID_IN_PROGRESS",
  GET_PLAT_BY_ID_ERROR: "GET_PLAT_ERROR_ERROR",

  CREATE_NEW_PLAT: "CREATE_NEW_PLAT",
  CREATE_NEW_PLAT_IN_PROGRESS: "CREATE_NEW_PLAT_IN_PROGRESS",
  CREATE_NEW_PLAT_ERROR: "CREATE_NEW_PLAT_ERROR",

  UPDATE_PLAT: "UPDATE_PLAT",
  UPDATE_PLAT_IN_PROGRESS: "UPDATE_PLAT_IN_PROGRESS",
  UPDATE_PLAT_ERROR: "UPDATE_PLAT_ERROR",

  DELETE_PLAT: "DELETE_PLAT",
  DELETE_PLAT_IN_PROGRESS: "DELETE_PLAT_IN_PROGRESS",
  DELETE_PLAT_ERROR: "DELETE_PLAT_ERROR"
};

export function getPlats(type = "") {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_PLATS_IN_PROGRESS });
    axiosInstance
      .get(`/api/plats/${type}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_PLATS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_PLATS_ERROR,
          payload: err?.response?.data || { message: "Failed to get Orders" }
        });
      });
  };
}

export function getPlatById(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_PLAT_BY_ID_IN_PROGRESS });
    axiosInstance
      .get(`/api/plat/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_PLAT_BY_ID,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_PLAT_BY_ID_ERROR,
          payload: err?.response?.data || { message: "Failed to get Order" }
        });
      });
  };
}

export function createPlat(order) {
  return function (dispatch) {
    dispatch({ type: TYPES.CREATE_NEW_PLAT_IN_PROGRESS });
    axiosInstance
      .post("/api/plats/create", order)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Plat Added Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });

        dispatch({
          type: TYPES.CREATE_NEW_PLAT,
          payload: response
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CREATE_NEW_PLAT_ERROR,
          payload: err?.response?.data || {
            message: "Failed to create new order"
          }
        });
      });
  };
}

export function editPlat(id, plat) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_PLAT_IN_PROGRESS });
    axiosInstance
      .put(`/api/plats/update/${id}`, plat)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Plat Updated Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });

        dispatch({
          type: TYPES.UPDATE_PLAT,
          payload: response
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_PLAT_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Edit Plat"
          }
        });
        Swal.fire({
          icon: "error",
          title: "Failed To Edit Plat",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
      });
  };
}

export function deletePlat(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.DELETE_PLAT_IN_PROGRESS });
    axiosInstance
      .delete(`/api/plats/delete/${id}`)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Plat Deleted Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
        dispatch({
          type: TYPES.DELETE_PLAT,
          payload: [id]
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.DELETE_PLAT_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Delete Plat"
          }
        });
      });
  };
}

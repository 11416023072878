import styled from "styled-components/macro";

export const InputLabel = styled.label`
  font-size: 1.1rem;
  font-weight: 600;
  color: #5e6278;
`;

export const InputLabelError = styled.label`
  font-size: 1.1rem;
  font-weight: 400;
  color: red;
`;

export const ErrorMessage = styled.label`
  font-size: 0.8rem;
  font-weight: 300;
  color: red;
  padding-left: 3em;
`;

import { TYPES } from "./actions";

let initState = {
  toasts : [],
};

let id = 0;

export function toasterReducer(state = initState, action) {
  const { payload, type } = action;

  switch (type) {
    case TYPES.ADD_TOAST:
      return {
        toasts: [{...payload, id: id++}, ...state.toasts]
      }

    case TYPES.REMOVE_TOAST: {
      let toasts = state.toasts.filter(toast => toast.id !== payload)
      return { toasts }
    }

    default:
      return state;
  }
}
import { TYPES } from "./actions";

let initState = {
  event: {},
  getEventIsLoading: false,
  getEventError: null,

  updateEventIsLoading: false,
  updateEventError: null,

  sideEvent: {},
  sideEvents: [],
  isLoading: false,
  error: null
};

export function eventAsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_SIDE_EVENTS:
      return {
        ...state,
        sideEvents: action.payload,
        isLoading: false
      };
    case TYPES.GET_SIDE_EVENTS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case TYPES.GET_SIDE_EVENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case TYPES.GET_SIDE_EVENT_BY_ID:
      return {
        ...state,
        sideEvent: action.payload,
        isLoading: false
      };
    case TYPES.GET_SIDE_EVENT_BY_ID_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case TYPES.GET_SIDE_EVENT_BY_ID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case TYPES.CREATE_SIDE_EVENT:
      return {
        ...state,
        sideEvent: action.payload,
        isLoading: false
      };
    case TYPES.CREATE_SIDE_EVENT_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case TYPES.CREATE_SIDE_EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case TYPES.UPDATE_SIDE_EVENT:
      return {
        ...state,
        sideEvent: action.payload,
        isLoading: false
      };
    case TYPES.UPDATE_SIDE_EVENT_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case TYPES.UPDATE_SIDE_EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case TYPES.CONFIRM_SIDE_EVENT:
      return {
        ...state,
        sideEvent: action.payload,
        isLoading: false
      };
    case TYPES.CONFIRM_SIDE_EVENT_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case TYPES.CONFIRM_SIDE_EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case TYPES.REJECT_SIDE_EVENT:
      return {
        ...state,
        sideEvent: action.payload,
        isLoading: false
      };
    case TYPES.REJECT_SIDE_EVENT_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case TYPES.REJECT_SIDE_EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case TYPES.DELETE_SIDE_EVENT:
      const newSideEvents = state?.sideEvents?.filter((sideEvent) => {
        return !action?.payload?.includes(sideEvent?._id);
      });
      return {
        ...state,
        sideEvents: newSideEvents,
        isLoading: false
      };
    case TYPES.DELETE_SIDE_EVENT_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case TYPES.DELETE_SIDE_EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case TYPES.GET_EVENT:
      return {
        ...state,
        event: action.payload,
        getEventIsLoading: false
      };
    case TYPES.GET_EVENT_IN_PROGRESS:
      return {
        ...state,
        getEventIsLoading: true
      };
    case TYPES.GET_EVENT_ERROR:
      return {
        ...state,
        getEventError: action.payload,
        getEventIsLoading: false
      };

    case TYPES.UPDATE_EVENT:
      return {
        ...state,
        event: action.payload,
        updateEventIsLoading: false
      };
    case TYPES.UPDATE_EVENT_IN_PROGRESS:
      return {
        ...state,
        updateEventIsLoading: true
      };
    case TYPES.UPDATE_EVENT_ERROR:
      return {
        ...state,
        updateEventError: action.payload,
        updateEventIsLoading: false
      };
    default:
      return state;
  }
}

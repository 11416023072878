import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";

export const TYPES = {
  GET_MENU_SETTINGS: "GET_MENU_SETTINGS",
  GET_MENU_SETTINGS_IN_PROGRESS: "GET_MENU_SETTINGS_IN_PROGRESS",
  GET_MENU_SETTINGS_ERROR: "GET_MENU_SETTINGS_ERROR",

  UPDATE_MENU_SETTINGS: "UPDATE_MENU_SETTINGS",
  UPDATE_MENU_SETTINGS_IN_PROGRESS: "UPDATE_MENU_SETTINGS_IN_PROGRESS",
  UPDATE_MENU_SETTINGS_ERROR: "UPDATE_MENU_SETTINGS_ERROR"
};

export function getMenuSettings() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_MENU_SETTINGS_IN_PROGRESS });
    axiosInstance
      .get(`/api/menuSettings/`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_MENU_SETTINGS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_MENU_SETTINGS_ERROR,
          payload: err?.response?.data || { message: "Failed to get menu settings" }
        });
      });
  };
}

export function updateMenuSettings(menuSettings) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_MENU_SETTINGS_IN_PROGRESS });
    axiosInstance
      .put("/api/menuSettings/update", menuSettings)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Menu settings Updated Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });

        dispatch({
          type: TYPES.CREATE_BACKTEST,
          payload: response
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_MENU_SETTINGS_ERROR,
          payload: err?.response?.data || {
            message: "Failed to update menu settings"
          }
        });
      });
  };
}

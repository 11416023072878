import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ProLayout, DefaultFooter } from "@ant-design/pro-layout";
import { ProConfigProvider } from "@ant-design/pro-provider";
import moment from "moment";
import { getEvent } from "redux/event/actions";
import { Button, Card, Col, Row, Switch as Toggle, Statistic, Alert } from "antd";
import RegistrationForm from "./registrationForm";
import { useParams } from "react-router-dom";
import Img1 from "../../assets/events.svg";
import RahalLogo from "../../assets/rahal_event.png";
import SpinLoader from "components/SpinLoader";
import { verifyLink } from "redux/links/actions";
import { getCategories } from "redux/categories/actions";
import {
  InstagramOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  FacebookOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import "moment/locale/fr"; // Import the French locale

moment.locale("fr"); // Set the locale to French

const { Countdown } = Statistic;

const Register = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { event, getEventIsLoading } = useSelector((state) => state.eventAs);
  const { link, verifyLinkIsLoading } = useSelector((state) => state.links);
  const { categories, getCategoriesIsLoading } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(getEvent());
    dispatch(verifyLink(id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const [pathname, setPathname] = useState("/");
  let theme = localStorage.getItem("theme");
  const [settings, setSetting] = useState({
    logo: () => <img src={RahalLogo} alt="logo" />,
    title: "RAHAL EVENT",
    fixSiderbar: false,
    layout: "mix",
    splitMenus: true,
    menuRender: false,
    contentStyle: { padding: 16 } // Ajustez cette valeur pour réduire la marge
  });

  const handleChangeThemeMode = (checked) => {
    if (checked) {
      setSetting((prevState) => ({
        ...prevState,
        navTheme: "light"
      }));
      localStorage.setItem("theme", "light");
    } else {
      setSetting((prevState) => ({
        ...prevState,
        navTheme: "realDark"
      }));
      localStorage.setItem("theme", "realDark");
    }
  };

  if (getEventIsLoading || verifyLinkIsLoading || getCategoriesIsLoading) return <SpinLoader />;
  console.log({ link });

  return (
    <ProConfigProvider hashed={false}>
      <ProLayout
        style={{ minHeight: "100vh" }}
        footerRender={() => (
          <DefaultFooter
            links={[
              {
                key: "ig",
                title: <InstagramOutlined />,
                href: "https://www.instagram.com/rahalmaitretraiteur.official/",
                blankTarget: true
              },
              {
                key: "fa",
                title: <FacebookOutlined />,
                href: "https://web.facebook.com/rahaltraiteur",
                blankTarget: true
              },
              {
                key: "in",
                title: <LinkedinOutlined />,
                href: "https://www.linkedin.com/company/rahal-group",
                blankTarget: true
              },
              {
                key: "tw",
                title: <TwitterOutlined />,
                href: "https://twitter.com/rahaltraiteur",
                blankTarget: true
              }
            ]}
            copyright={
              <Row gutter={[8, 4]}>
                <Col span={24}>
                  <img src={RahalLogo} alt="Logo Rahal" width={"80px"} />
                </Col>
                <Col span={24}>© Copyright 2024 Rahal Event, Tous droits réservés.</Col>
              </Row>
            }
          />
        )}
        bgLayoutImgList={[
          {
            src: Img1,
            bottom: 0,
            left: 0,
            width: "331px"
          }
        ]}
        location={{ pathname }}
        actionsRender={(props) => {
          return [
            <Toggle
              checkedChildren="🌞"
              unCheckedChildren="🌜"
              defaultChecked={theme === "light"}
              onChange={handleChangeThemeMode}
            />,
            <Button href="/login">Connexion</Button>
          ];
        }}
        onMenuHeaderClick={(e) => (
          <Link
            to={"/"}
            onClick={() => {
              setPathname("/");
            }}
          />
        )}
        menuItemRender={(item, dom) => (
          <Link
            to={item?.path}
            onClick={() => {
              setPathname(item.path || "/");
            }}
          >
            {dom}{" "}
          </Link>
        )}
        {...settings}
      >
        <div
          id="test-pro-layout"
          style={{
            minHeight: "100vh",
            width: "100%"
          }}
        >
          <Row gutter={[8, 16]}>
            {!link?.valid && (
              <Col span={24}>
                <Alert
                  message={
                    <span style={{ textAlign: "center" }}>
                      <p
                        style={{
                          margin: "0px",
                          padding: "0px",
                          fontWeight: "500",
                          fontSize: "13px"
                        }}
                      >
                        Nous regrettons de vous informer que ce lien d'inscription est invalide ou
                        que le quota d'inscriptions a été dépassé. Si vous avez besoin d'une
                        assistance supplémentaire, n'hésitez pas à contacter notre équipe de
                        support.
                      </p>
                    </span>
                  }
                  type="error"
                />
              </Col>
            )}

            <Col span={24}>
              <Alert
                message={
                  <span style={{ textAlign: "center" }}>
                    <Countdown
                      style={{ fontWeight: "500", padding: "0", margin: "0", fontSize: "16px" }}
                      value={moment(
                        `${event?.startDate} ${event?.eventStartTime}`,
                        "YYYY-MM-DD HH:mm:ss"
                      ).valueOf()}
                      format="[L'événement commence dans] D [Jours] H [Heures] m [Minutes] s [Secondes]"
                    />
                  </span>
                }
                type="info"
              />
            </Col>
            <Col span={24}>
              <Card>
                <Row gutter={[16, 4]}>
                  <Col
                    xs={24}
                    sm={24}
                    md={3}
                    lg={3}
                    xl={3}
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    <img src={event?.logo} alt="Logo de l'événement" width={"100%"} />
                  </Col>

                  <Col xs={24} sm={24} md={21} lg={21} xl={21}>
                    <Row gutter={[4, 4]}>
                      <Col span={24}>
                        <Row gutter={24}>
                          <Col span={24}>
                            <p
                              style={{
                                opacity: 0.6,
                                fontWeight: "500",
                                fontSize: "12px",
                                padding: "0",
                                margin: "0"
                              }}
                            >
                              Début:{" "}
                              {moment(
                                `${event?.startDate} ${event?.eventStartTime}`,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("HH:mm dddd DD MMMM YYYY")}{" "}
                            </p>

                            <p
                              style={{
                                opacity: 0.6,
                                fontWeight: "500",
                                fontSize: "12px",
                                padding: "0",
                                margin: "0"
                              }}
                            >
                              Fin:{" "}
                              {moment(
                                `${event?.endDate} ${event?.eventEndTime}`,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("HH:mm dddd DD MMMM YYYY")}
                            </p>
                            <p
                              style={{
                                opacity: 0.6,
                                fontWeight: "500",
                                fontSize: "12px",
                                padding: "0",
                                margin: "0"
                              }}
                            >
                              Lieu: {event?.eventAddress}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <h3 style={{ padding: "0", margin: "0" }}>{event?.eventName}</h3>
                      </Col>
                      <Col span={24}>
                        <p style={{ fontWeight: "500", fontSize: "14px" }}>{event?.description}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>

            {link?.valid && (
              <Col span={24}>
                <RegistrationForm
                  link={link}
                  categories={categories}
                  // country={countries?.find((country) => {
                  //   attendee?.country === country?.name;
                  // })}
                />
              </Col>
            )}
          </Row>
        </div>
      </ProLayout>
    </ProConfigProvider>
  );
};

export default Register;

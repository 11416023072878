import React from "react";
import { Spin, Row, Col } from "antd";

function SpinLoader() {
  return (
    <Row
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"
      }}
    >
      <Col span={24}>
        <Spin />
      </Col>
    </Row>
  );
}

export default SpinLoader;

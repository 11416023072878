import styled from "styled-components/macro";

export const Container = styled.div`
  background-color: ${(props) => props.color || "white"};
  margin-top: ${(props) => props.marginTop || "0"};
  padding: ${(props) => props.padding || "30px"};
  min-width: 100%;
  width: 100%;
  font-size: 16px;
  min-height: 100%;
`;

export const SmallTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  opacity: 0.6;
`;

export const InputLabel = styled.label`
  font-size: 1.1rem;
  font-weight: 600;
  color: #5e6278;
`;

export const InputLabelError = styled.label`
  font-size: 1.1rem;
  font-weight: 400;
  color: red;
`;

export const ErrorMessage = styled.label`
  font-size: 0.8rem;
  font-weight: 300;
  color: red;
  padding-left: 3em;
`;

export const urlPrepare = (url, paramsObject, queryObject) => {
  if (paramsObject) {
    Object.keys(paramsObject).forEach((key) => {
      url = url.replace(`:${key}`, paramsObject[key]);
    });
  }

  if (queryObject) {
    url = url.concat("?");
    Object.keys(queryObject).forEach((key) => {
      url = url.concat(queryObject[key] ? `${key}=${queryObject[key]}&` : "");
    });
  }
  return url;
};

export const isEmptyValue = (value) => {
  return (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

import React from "react";

export const TYPES = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

export const Taost = ({ message, type, onClose }) => {
  return (
    <div
      className={`toast toast-${TYPES[type.toUpperCase()]}`}
      aria-live="polite"
    >
      <button type="button" className="toast-close-button" onClick={onClose}>
        ×
      </button>
      <div className="toast-message">{message}</div>
    </div>
  );
};

export default Taost;

import { TYPES } from "./actions";

let initState = {
  users: [],
  user: {},
  crm: {},

  resetPasswordIsLoading: false,
  resetPasswordError: null,
  resetPasswordResponse: null,

  triggerResetPasswordIsLoading: false,
  triggerResetPasswordError: null,
  triggerResetPasswordResponse: null,

  usersByOrg: [],
  updateIsLoading: false,
  addUserIsLoading: false,
  addManagerIsLoading: false,
  usersByRole: [],
  getUsersByRoleIsLoading: false,
  assignedClients: "",
  investment: {},
  investmentError: {},
  isLoading: false,
  assigningClientsIsLoading: false,
  newPamInvestmentInProgress: false,
  error: null
};

export function userReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.TRIGGER_RESET_PASSWORD_IN_PROGRESS: {
      return {
        ...state,
        triggerResetPasswordIsLoading: true
      };
    }
    case TYPES.TRIGGER_RESET_PASSWORD: {
      return {
        ...state,
        triggerResetPasswordIsLoading: false,
        triggerResetPasswordResponse: action.payload
      };
    }
    case TYPES.TRIGGER_RESET_PASSWORD_ERROR: {
      return {
        ...state,
        triggerResetPasswordIsLoading: false,
        triggerResetPasswordError: action.payload
      };
    }
    case TYPES.RESET_USER_PASSWORD_IN_PROGRESS: {
      return {
        ...state,
        resetPasswordIsLoading: true
      };
    }
    case TYPES.RESET_USER_PASSWORD: {
      return {
        ...state,
        resetPasswordIsLoading: false,
        resetPasswordResponse: action.payload
      };
    }
    case TYPES.RESET_USER_PASSWORD_ERROR: {
      return {
        ...state,
        resetPasswordIsLoading: false,
        resetPasswordError: action.payload
      };
    }
    case TYPES.GET_USERS_BY_ORG_IN_PROGRESS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.GET_USERS_BY_ORG: {
      return {
        ...state,
        isLoading: false,
        usersByOrg: action.payload
      };
    }
    case TYPES.GET_USERS_BY_ORG_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }
    case TYPES.DELETE_USER_IN_PROGRESS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.DELETE_USER: {
      const newUsers = state.usersByRole.filter((user) => user._id !== action.payload?._id);

      return {
        ...state,
        isLoading: false,
        usersByRole: newUsers
      };
    }
    case TYPES.DELETE_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }
    case TYPES.ACTIVATE_USER_IN_PROGRESS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.ACTIVATE_USER: {
      return {
        ...state,
        isLoading: false,
        user: action.payload
      };
    }

    case TYPES.ACTIVATE_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }
    case TYPES.DEACTIVATE_USER_IN_PROGRESS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.DEACTIVATE_USER: {
      return {
        ...state,
        isLoading: false,
        user: action.payload
      };
    }
    case TYPES.DEACTIVATE_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }
    case TYPES.ADD_USER_IN_PROGRESS: {
      return {
        addUserIsLoading: true
      };
    }
    case TYPES.ADD_USER: {
      return {
        user: action.payload,
        addUserIsLoading: false
      };
    }
    case TYPES.ADD_USER_ERROR: {
      return {
        error: action.payload,
        addUserIsLoading: false
      };
    }
    case TYPES.UPDATE_USER_IN_PROGRESS: {
      return {
        updateIsLoading: true
      };
    }
    case TYPES.UPDATE_USER: {
      return {
        user: action.payload,
        updateIsLoading: false
      };
    }
    case TYPES.UPDATE_USER_ERROR: {
      return {
        error: action.payload,
        updateIsLoading: false
      };
    }
    case TYPES.GET_USER_BY_ID_IN_PROGRESS: {
      return {
        isLoading: true
      };
    }
    case TYPES.GET_USER: {
      return {
        ...state,
        user: action.payload,
        id: action.payload._id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        title: action.payload.title,
        redirect: "DASHBOARD"
      };
    }
    case TYPES.ADD_MANAGER: {
      return {
        ...state,
        addManagerIsLoading: false,
        user: action.payload
      };
    }
    case TYPES.ADD_MANAGER_IN_PROGRESS: {
      return {
        ...state,
        addManagerIsLoading: true
      };
    }
    case TYPES.ADD_MANAGER_ERROR: {
      return {
        ...state,
        addManagerIsLoading: false
      };
    }

    case TYPES.GET_MANAGERS_BY_SUP_ID: {
      return {
        ...state,
        isLoading: false,
        users: action.payload
      };
    }
    case TYPES.GET_MANAGERS_BY_SUP_ID_IN_PROGRESS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.GET_MANAGERS_BY_SUP_ID_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }

    case TYPES.GET_USERS_BY_ROLE_IN_PROGRESS: {
      return {
        ...state,
        getUsersByRoleIsLoading: true
      };
    }
    case TYPES.GET_USER_BY_ROLE_ERROR: {
      return {
        ...state,
        getUsersByRoleIsLoading: false
      };
    }
    case TYPES.PAM_NEW_INVESTMENT_IN_PROGRESS: {
      return {
        ...state,
        newPamInvestmentInProgress: true
      };
    }

    case TYPES.ASSIGN_CLIENTS_TO_MANAGER_IN_PROGRESS: {
      return {
        ...state,
        assigningClientsIsLoading: true
      };
    }

    case TYPES.GET_USERS: {
      return {
        ...state,
        users: action.payload
      };
    }
    case TYPES.GET_USERS_ROLE: {
      return {
        ...state,
        usersByRole: action.payload,
        getUsersByRoleIsLoading: false
      };
    }

    case TYPES.GET_USER_BY_ID: {
      return {
        ...state,
        user: action.payload,
        isLoading: false
      };
    }
    case TYPES.GET_CRMBYCLIENTID: {
      return {
        ...state,
        crm: action.payload
      };
    }
    case TYPES.ASSIGN_CLIENTS_TO_MANAGER: {
      return {
        ...state,
        assignedClients: action.payload,
        assigningClientsIsLoading: false
      };
    }
    case TYPES.PAM_NEW_INVESTMENT: {
      return {
        ...state,
        investment: action.payload,
        newPamInvestmentInProgress: false
      };
    }
    case TYPES.PAM_NEW_INVESTMENT_ERROR: {
      return {
        ...state,
        investmentError: action.payload,
        newPamInvestmentInProgress: false
      };
    }
    default:
      return state;
  }
}

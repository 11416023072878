import { TYPES } from "./actions";

let initState = {
  attendee: {},
  getAttendeeByIdIsLoading: false,
  getAttendeeByIdError: null,

  logAttendeeInOrOutIsLoading: false,
  logAttendeeInOrOutError: null,

  createAttendeeIsLoading: false,
  createAttendeeError: null,

  createAttendeesError: null,
  createAttendeesIsLoading: false,

  attendees: [],

  getAttendeesIsLoading: false,
  getAttendeesError: null,

  updateAttendeeIsLoading: false,
  updateAttendeeError: null,

  deleteAttendeeIsLoading: false,
  deleteAttendeeError: null
};

export function attendeesAsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.LOG_ATTENDEE_IN_OR_OUT:
      return {
        ...state,
        logAttendeeInOrOutIsLoading: false
      };
    case TYPES.LOG_ATTENDEE_IN_OR_OUT_IN_PROGRESS:
      return {
        ...state,
        logAttendeeInOrOutIsLoading: true
      };
    case TYPES.LOG_ATTENDEE_IN_OR_OUT_ERROR:
      return {
        ...state,
        logAttendeeInOrOutIsLoading: false,
        logAttendeeInOrOutError: action.payload
      };

    case TYPES.CREATE_NEW_ATTENDEES:
      return {
        ...state,
        attendees: action.payload,
        createAttendeesIsLoading: false
      };
    case TYPES.CREATE_NEW_ATTENDEES_IN_PROGRESS:
      return {
        ...state,
        createAttendeesIsLoading: true
      };
    case TYPES.CREATE_NEW_ATTENDEES_ERROR:
      return {
        ...state,
        createAttendeesIsLoading: false,
        createAttendeesError: action.payload
      };

    case TYPES.UPDATE_ATTENDEE:
      return {
        ...state,
        attendee: action.payload,
        updateAttendeeIsLoading: false
      };
    case TYPES.UPDATE_ATTENDEE_IN_PROGRESS:
      return {
        ...state,
        updateAttendeeIsLoading: true
      };
    case TYPES.UPDATE_ATTENDEE_ERROR:
      return {
        ...state,
        updateAttendeeIsLoading: false,
        updateAttendeeError: action.payload
      };

    case TYPES.GET_ATTENDEE_BY_ID:
      return {
        ...state,
        attendee: action.payload,
        getAttendeeByIdIsLoading: false
      };
    case TYPES.GET_ATTENDEE_BY_ID_IN_PROGRESS:
      return {
        ...state,
        getAttendeeByIdIsLoading: true
      };
    case TYPES.GET_ATTENDEE_BY_ID_ERROR:
      return {
        ...state,
        getAttendeeByIdIsLoading: false,
        getAttendeeByIdError: action.payload
      };

    case TYPES.CREATE_NEW_ATTENDEE:
      return {
        ...state,
        attendee: action.payload,
        createAttendeeIsLoading: false
      };
    case TYPES.CREATE_NEW_ATTENDEE_IN_PROGRESS:
      return {
        ...state,
        createAttendeeIsLoading: true
      };
    case TYPES.CREATE_NEW_ATTENDEE_ERROR:
      return {
        ...state,
        createAttendeeIsLoading: false,
        createAttendeeError: action.payload
      };

    case TYPES.GET_ATTENDEES:
      return {
        ...state,
        attendees: action.payload,
        getAttendeesIsLoading: false
      };
    case TYPES.GET_ATTENDEES_IN_PROGRESS:
      return {
        ...state,
        getAttendeesIsLoading: true
      };
    case TYPES.GET_ATTENDEES_ERROR:
      return {
        ...state,
        getAttendeesIsLoading: false,
        getAttendeesError: action.payload
      };

    case TYPES.GET_ATTENDEES_BY_LINKS_IDS:
      return {
        ...state,
        attendees: action.payload,
        getAttendeesIsLoading: false
      };
    case TYPES.GET_ATTENDEES_BY_LINKS_IDS_IN_PROGRESS:
      return {
        ...state,
        getAttendeesIsLoading: true
      };
    case TYPES.GET_ATTENDEES_BY_LINKS_IDS_ERROR:
      return {
        ...state,
        getAttendeesIsLoading: false,
        getAttendeesError: action.payload
      };

    case TYPES.GET_ATTENDEES_BY_MANAGER_ID:
      return {
        ...state,
        attendees: action.payload,
        getAttendeesIsLoading: false
      };
    case TYPES.GET_ATTENDEES_BY_MANAGER_ID_IN_PROGRESS:
      return {
        ...state,
        getAttendeesIsLoading: true
      };
    case TYPES.GET_ATTENDEES_BY_MANAGER_ID_ERROR:
      return {
        ...state,
        getAttendeesIsLoading: false,
        getAttendeesError: action.payload
      };

    case TYPES.DELETE_ATTENDEE:
      const newPlats = state?.attendees?.filter((attendee) => {
        return !action?.payload?.includes(attendee?._id);
      });

      return {
        ...state,
        attendees: newPlats,
        deleteAttendeeIsLoading: false
      };
    case TYPES.DELETE_ATTENDEE_IN_PROGRESS:
      return {
        ...state,
        deleteAttendeeIsLoading: true
      };
    case TYPES.DELETE_ATTENDEE_ERROR:
      return {
        ...state,
        deleteAttendeeIsLoading: false,
        deleteAttendeeError: action.payload
      };
    default:
      return state;
  }
}

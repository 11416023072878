import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";
import { message } from "antd"

export const TYPES = {
  GET_ATTENDEES: "GET_ATTENDEES",
  GET_ATTENDEES_IN_PROGRESS: "GET_ATTENDEES_IN_PROGRESS",
  GET_ATTENDEES_ERROR: "GET_ATTENDEES_ERROR",

  GET_ATTENDEES_BY_LINKS_IDS: "GET_ATTENDEES_BY_LINKS_IDS",
  GET_ATTENDEES_BY_LINKS_IDS_IN_PROGRESS: "GET_ATTENDEES_BY_LINKS_IDS_IN_PROGRESS",
  GET_ATTENDEES_BY_LINKS_IDS_ERROR: "GET_ATTENDEES_BY_LINKS_IDS_ERROR",

  GET_ATTENDEES_BY_MANAGER_ID: "GET_ATTENDEES_BY_MANAGER_ID",
  GET_ATTENDEES_BY_MANAGER_ID_IN_PROGRESS: "GET_ATTENDEES_BY_MANAGER_ID_IN_PROGRESS",
  GET_ATTENDEES_BY_MANAGER_ID_ERROR: "GET_ATTENDEES_BY_MANAGER_ID_ERROR",

  LOG_ATTENDEE_IN_OR_OUT: "LOG_ATTENDEE_IN_OR_OUT",
  LOG_ATTENDEE_IN_OR_OUT_IN_PROGRESS: "LOG_ATTENDEE_IN_OR_OUT_IN_PROGRESS",
  LOG_ATTENDEE_IN_OR_OUT_ERROR: "LOG_ATTENDEE_IN_OR_OUT_ERROR",

  GET_ATTENDEE_BY_ID: "GET_ATTENDEE_BY_ID",
  GET_ATTENDEE_BY_ID_IN_PROGRESS: "GET_ATTENDEE_BY_ID_IN_PROGRESS",
  GET_ATTENDEE_BY_ID_ERROR: "GET_ATTENDEE_BY_ID_ERROR",

  CREATE_NEW_ATTENDEE: "CREATE_NEW_ATTENDEE",
  CREATE_NEW_ATTENDEE_IN_PROGRESS: "CREATE_NEW_ATTENDEE_IN_PROGRESS",
  CREATE_NEW_ATTENDEE_ERROR: "CREATE_NEW_ATTENDEE_ERROR",

  CREATE_NEW_ATTENDEES: "CREATE_NEW_ATTENDEES",
  CREATE_NEW_ATTENDEES_IN_PROGRESS: "CREATE_NEW_ATTENDEES_IN_PROGRESS",
  CREATE_NEW_ATTENDEES_ERROR: "CREATE_NEW_ATTENDEES_ERROR",

  UPDATE_ATTENDEE: "UPDATE_ATTENDEE",
  UPDATE_ATTENDEE_IN_PROGRESS: "UPDATE_ATTENDEE_IN_PROGRESS",
  UPDATE_ATTENDEE_ERROR: "UPDATE_ATTENDEE_ERROR",

  DELETE_ATTENDEE: "DELETE_ATTENDEE",
  DELETE_ATTENDEE_IN_PROGRESS: "DELETE_ATTENDEE_IN_PROGRESS",
  DELETE_ATTENDEE_ERROR: "DELETE_ATTENDEE_ERROR"
};

export function attendeeInOrOut(id, room) {
  return function (dispatch) {
    console.log({ room });
    dispatch({ type: TYPES.LOG_ATTENDEE_IN_OR_OUT });
    axiosInstance
      .post(`/api/attendeeInOrOut/${id}`, { room })
      .then((response) => {
        dispatch({
          type: TYPES.LOG_ATTENDEE_IN_OR_OUT_IN_PROGRESS,
          payload: response?.data
        });
        message.success("Attendee logged successfully !")

      })
      .catch((err) => {
        dispatch({
          type: TYPES.LOG_ATTENDEE_IN_OR_OUT_ERROR,
          payload: err?.response?.data || {
            message: "Failed to create new attendee"
          }
        });
        message.error("Failed to log attendee !")
      });
  };
}

export function getAttendeesByLinksIds(ids) {
  console.log("ids >>>>>>>>>>>>>>", { ids });
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ATTENDEES_BY_LINKS_IDS_IN_PROGRESS });
    axiosInstance
      .get(`/api/attendeesByLinksIds/${ids}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ATTENDEES_BY_LINKS_IDS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_ATTENDEES_BY_LINKS_IDS_ERROR,
          payload: err?.response?.data || { message: "Failed to get Attendees" }
        });
      });
  };
}

export function getAttendeesByManagerId(id) {
  console.log("ids >>>>>>>>>>>>>>", { id });
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ATTENDEES_BY_LINKS_IDS_IN_PROGRESS });
    axiosInstance
      .get(`/api/attendeesByManagerId/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ATTENDEES_BY_LINKS_IDS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_ATTENDEES_BY_LINKS_IDS_ERROR,
          payload: err?.response?.data || { message: "Failed to get Attendees" }
        });
      });
  };
}

export function getAttendees() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ATTENDEES_IN_PROGRESS });
    axiosInstance
      .get(`/api/attendees/`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ATTENDEES,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_ATTENDEES_ERROR,
          payload: err?.response?.data || { message: "Failed to get Orders" }
        });
      });
  };
}

export function getAttendeeById(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ATTENDEE_BY_ID_IN_PROGRESS });
    axiosInstance
      .get(`/api/attendee/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ATTENDEE_BY_ID,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_ATTENDEE_BY_ID_ERROR,
          payload: err?.response?.data || { message: "Failed to get Order" }
        });
      });
  };
}

export function createAttendee(order) {
  return function (dispatch) {
    dispatch({ type: TYPES.CREATE_NEW_ATTENDEE_IN_PROGRESS });
    axiosInstance
      .post("/api/attendee/", order)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Attendee Added Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });

        dispatch({
          type: TYPES.CREATE_NEW_ATTENDEE,
          payload: response
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CREATE_NEW_ATTENDEE_ERROR,
          payload: err?.response?.data || {
            message: "Failed to create new order"
          }
        });
        console.log({ err });
        Swal.fire({
          icon: "error",
          title: err?.message,
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
      });
  };
}

export function createAttendees(order) {
  return function (dispatch) {
    dispatch({ type: TYPES.CREATE_NEW_ATTENDEES_IN_PROGRESS });
    axiosInstance
      .post("/api/attendees/", order)
      .then((response) => {
        dispatch({
          type: TYPES.CREATE_NEW_ATTENDEES,
          payload: response?.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CREATE_NEW_ATTENDEES_ERROR,
          payload: err?.response?.data || {
            message: "Failed to create new attendee"
          }
        });
      });
  };
}

export function editAttendee(id, attendee) {
  return function (dispatch) {
    dispatch({ type: TYPES.UPDATE_ATTENDEE_IN_PROGRESS });
    axiosInstance
      .put(`/api/attendee/${id}`, attendee)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Attendee Updated Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });

        dispatch({
          type: TYPES.UPDATE_ATTENDEE,
          payload: response
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_ATTENDEE_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Edit Attendee"
          }
        });
        Swal.fire({
          icon: "error",
          title: "Failed To Edit Attendee",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
      });
  };
}

export function deleteAttendee(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.DELETE_ATTENDEE_IN_PROGRESS });
    axiosInstance
      .delete(`/api/attendee/${id}`)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Attendee Deleted Successfully",
          confirmButtonColor: "#4096ff",
          confirmButtonText: "Done"
        });
        dispatch({
          type: TYPES.DELETE_ATTENDEE,
          payload: [id]
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.DELETE_ATTENDEE_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Delete Attendee"
          }
        });
      });
  };
}

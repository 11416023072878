import { TYPES } from "./actions";

let initState = {
  menuSettings: {},
  getMenuSettingsIsLoading: false,
  getMenuSettingsError: null,

  updateMenuSettingsIsLoading: false,
  updateMenuSettingsError: null
};

export function menuSettingsAsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_MENU_SETTINGS:
      return {
        ...state,
        menuSettings: action.payload,
        getMenuSettingsIsLoading: false
      };
    case TYPES.GET_MENU_SETTINGS_IN_PROGRESS:
      return {
        ...state,
        getMenuSettingsIsLoading: true
      };
    case TYPES.GET_MENU_SETTINGS_ERROR:
      return {
        ...state,
        getMenuSettingsIsLoading: false,
        getMenuSettingsError: action.payload
      };

    case TYPES.UPDATE_MENU_SETTINGS:
      return {
        ...state,
        menuSettings: action.payload,
        updateMenuSettingsIsLoading: false
      };
    case TYPES.UPDATE_MENU_SETTINGS_IN_PROGRESS:
      return {
        ...state,
        updateMenuSettingsIsLoading: true
      };
    case TYPES.UPDATE_MENU_SETTINGS_ERROR:
      return {
        ...state,
        updateMenuSettingsIsLoading: false,
        updateMenuSettingsError: action.payload
      };
    default:
      return state;
  }
}
